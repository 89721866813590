import { useState, useEffect } from "react";
import { Tooltip, Card, Typography } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import SortingArrow from "../../Common/SortingArrow";
import PlanService from "../../service/PlanService";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";

const PlanSer = new PlanService();
const ManageSubscriptionPlan = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [planListing, setPlanListing] = useState([]);
  const [filters, setFilters] = useState({ sort:'',order:'' });
  const handleSort = (field, key) => {
    setFilters({ ...filters, sort:field, order: key });
  };

  useEffect(() => {
    const filterPlans = async () => {
      try{
      setLoading(true);
      const response = await PlanSer.getPlanList(filters);
      setPlanListing(response?.data);
      setLoading(false);
      }catch(error){
        let msg = PlanSer.errorMessage(error);
        showAlert(msg, "error");
      }
    };
    
    filterPlans();
  }, [filters]);

  return (
    <>
      <Card>
        <div className="flex justify-between	mb-4 gap-5">
          <div className="">
            <Typography className="text-xl font-semibold" align={"left"}>
              Manage Subscription Plan
            </Typography>
          </div>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full  text-left	 text-sm  text-gray-500">
            <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  Plan Name <SortingArrow onSort={handleSort} field="name"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Amount <SortingArrow onSort={handleSort} field="amount"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Search College Limit <SortingArrow onSort={handleSort} field="search_restrict"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Upload Video Limit <SortingArrow onSort={handleSort} field="video_restrict"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Upload Image Limit <SortingArrow onSort={handleSort} field="image_restrict"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Status <SortingArrow onSort={handleSort} field="status"/>
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap vertical-top"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
             {planListing?.map((plan, index) => (
              <tr className="bg-white border-b">
                <th
                  scope="row"
                  className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                  onClick={() => navigate("")}
                >
                  {plan?.name}
                </th>
                <td className="px-3 py-4 vertical-top">${plan?.amount}</td>
                <td className="px-3 py-4 vertical-top">{plan?.search_restrict} </td>
                <td className="px-3 py-4 vertical-top">{plan?.video_restrict}</td>
                <td className="px-3 py-4 vertical-top">{plan?.image_restrict}</td>
                <td className="px-3 py-4 vertical-top">
                  <p
                    className={`text-xs font-medium bg-[#01940033] rounded-lg text-center p-2 capitalize ${
                      plan?.status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                      (plan?.status === 'rejected' || plan?.status === 'inactive' ) ? 'text-[#DC362E] bg-[#DC362E40]' : 'text-[#019400] bg-[#01940033]'
                    }`}>
                      {plan?.status}
                    </p>
                  </td>
                <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                  <Tooltip title="View">
                    <EyeOutlined  onClick={() => navigate(`/view-subscription-details/${plan?.id}`)}
                          className="mx-1.5 cursor-pointer"
                    />
                    </Tooltip>
                  <Tooltip title="Edit">
                    <EditOutlined
                      onClick={() => navigate(`/edit-subscription-details/${plan?.id}`)}
                      className="mx-1.5 cursor-pointer"
                    />
                  </Tooltip>
                </td>
              </tr>
             ))}
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
};
export default ManageSubscriptionPlan;