// context/AlertContext.js
"use client";
import React, { createContext, useState, useContext } from "react";
import Alerts from "../Component/Alerts/page";

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ text: "", type: "success", show: false });

  const showAlert = (text, type = "success") => {
    setAlert({ text, type, show: true });

    setTimeout(() => {
      setAlert({ ...alert, show: false });
    }, 3500);
  };

  return (
    <AlertContext.Provider value={{alert,showAlert}}>
      {children}
      {alert.show && <Alerts text={alert.text} type={alert.type} />}
    </AlertContext.Provider>
  );
};

export default AlertProvider;

export const useAlert = () => useContext(AlertContext);