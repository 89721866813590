import { useState } from "react";
import { Card, Divider, Tabs, Typography } from "antd";
import {
  LeftOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PersonalInformation from "./EditAthleteDetails/PersonalInformation";
import SocialMedia from "./EditAthleteDetails/SocialMedia";
import AcademicDetails from "./EditAthleteDetails/AcademicDetails";
import SportsDetails from "./EditAthleteDetails/SportsDetails";
const { TabPane } = Tabs;
const EditAthlete = () => {
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState("1");
  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <div>
            <Typography className="text-xl font-semibold" align={"left"}>
              <LeftOutlined
                className="text=lg text-black mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              Edit Athlete Details
            </Typography>
          </div>
        </div>
        <Divider />
        <Tabs activeKey={activeTabKey} onChange={(key) => setActiveTabKey(key)}>
          <TabPane tab="Personal Information" key="1">
            <PersonalInformation />
          </TabPane>
          <TabPane tab="Academic Details" key="2">
            <AcademicDetails />
          </TabPane>
          <TabPane tab="Sports Details" key="3">
            <SportsDetails />
          </TabPane>
          <TabPane tab="Uploaded Files" key="4">
            <SocialMedia />
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default EditAthlete;
