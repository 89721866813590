export const validateEmail = (email) => {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
};
  
export const validatePassLetter = (password) => {
    var pattern = /[a-zA-Z]/;
    return pattern.test(password);
};

export const validatePassNumber = (password) => {
    var pattern = /[0-9]/;
    return pattern.test(password);
};

export const validateGpaNumber = (value) => {
    var pattern = /^(?:[0-4](?:\.[0-9]{0,2})?|5(?:\.0{0,2})?)$/;
    return pattern.test(value);
};

export const validateSatNumber = (value) => {
    var pattern = /^(?:[0-7]?[0-9]{0,2}|800)(?:\.\d?)?$/;
    return pattern.test(value);
};

export const validateActNumber = (value, maxValue) => {
    var pattern = new RegExp(`^(?:[0-7]?[0-9]{0,2}|${maxValue})(?:\\.\\d?)?$`);
    return pattern.test(value);
};

export const validateCardNumber = (value) => {
    var result = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
    return result;
};

export const validateCardHolder = (value) => {
    var result = value.replace(/[^a-zA-Z\s]/g, '');
    return result;
};

export const validateCardDate = (value) => {
    var result = value
    .replace(/[^\d]/g, '')
    .replace(/(\d{2})(\d{2})?/, (_, p1, p2) => (p2 ? `${p1}/${p2}` : p1))
    .slice(0, 5);
    return result;
};

export const validateCvv = (value) => {
    var result = value.replace(/\D/g, '').slice(0, 4);
    return result;
};

export const getFileNameWithRandomNumber = (fileName) => {
    let randomNumber  = Math.floor(Math.random() * 1000000);
    const fileParts = fileName.split('.');
    const fileExtension = fileParts.pop();
    const baseName = fileParts.join('.');
    return `${baseName}_${randomNumber}.${fileExtension}`;
};

export const isValidYouTubeUrl = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=\n%\?]{11})(?:[&=%\?]{0,})/;
    return regex.test(url);
};

export const isValidUrl = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,})'+
      '(\\:\\d{1,5})?'+
      '(\\/[-a-zA-Z\\d%_.~+]*)*'+
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?'+
      '(\\#[-a-zA-Z\\d_]*)?$','i');
    return !!pattern.test(url);
  };

export const handleKeyDownOnLength = (event, maxLength) => {
    const currentValue = event.currentTarget.value;
    const isNumber = /[0-9]/.test(event.key);
    const isBackspaceOrDelete = event.key === 'Backspace' || event.key === 'Delete';
    const isArrowKey = event.key === 'ArrowLeft' || event.key === 'ArrowRight';
    
    if (isBackspaceOrDelete || isArrowKey) {
      return; // Allow the action
    }
  
    if (!isNumber) {
      event.preventDefault();
    }
  
    if (currentValue.length >= maxLength && isNumber) {
      event.preventDefault(); // Block input
    }
  };

  export const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    if (value.replace(/\D/g, '').length >= 10) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Phone number must be at least 10 digits.'));
  };

  export const validateUrl = (_, value) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' +
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,})' +
      '(\\:\\d{1,5})?' +
      '(\\/[-a-zA-Z\\d%_.~+]*)*' +
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' +
      '(\\#[-a-zA-Z\\d_]*)?$',
      'i'
    );
    if (!value || pattern.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid URL.'));
  };