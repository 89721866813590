import { Tooltip } from "antd";
import { useState, useEffect, useCallback } from "react";
import PaginationComponent from "../../../Common/Pagination";
import SortingArrow from "../../../Common/SortingArrow";
import { DownloadOutlined } from "@ant-design/icons";
import MotionService from "../../../service/MotionService";
import { useAlert } from "../../../contexts/AlertProvider";
import { useLoader } from "../../../contexts/LoadingProvider";
import moment from 'moment';
//getMotionList
const MotionSer = new MotionService();
const MotionAIReportGeneration = ({ filters, setFilters, activeTab }) => {
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [motionListing, setMotionListing] = useState([]);
  
  const fetchData = useCallback(async () => {
    try {
      //setLoading(true);
      const response = await MotionSer.getMotionList(filters);
      setMotionListing(response?.data);
      //setLoading(false);
    } catch (error) {
      let msg = MotionSer.errorMessage(error);
      showAlert(msg, "error");
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (activeTab === "2") {
      fetchData();
    }
  }, [activeTab, fetchData]);

  const exportData = async () =>{
    setLoading(true);
    const response = await MotionSer.getExportMotionList();
    if(response?.link){
      window.open(response?.link, '_blank');
    }
      setLoading(false);
  }

  const handlePageChange = (page, size) => {
    setFilters(prevFilters => ({ ...prevFilters, page:page, limit: size }));
  };

  const handleSort = (field, key) => {
    setFilters(prevFilters => ({ ...prevFilters, page:1, sort:field, order: key }));
  };

  const exportPDF = async (id) =>{
    setLoading(true);
    try {
      const response = await MotionSer.getMotionPdf(id);
     window.open('https://devapi.collegerecruitpro.com/generated.pdf', '_blank');
    }catch (error) {
      let msg = MotionSer.errorMessage(error);
      showAlert(msg, "error");
    }
    setLoading(false);
  }

  return (
    <>
      <div className="flex justify-end mb-4">
        <Tooltip title="Export">
          <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" onClick={exportData} />
        </Tooltip>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-left text-sm  text-gray-500">
          <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Transaction ID <SortingArrow onSort={handleSort}  field="transaction_id"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Transaction date & time <SortingArrow onSort={handleSort} field="created_at"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Athlete name <SortingArrow onSort={handleSort} field="first_name"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Email address <SortingArrow onSort={handleSort} field="email"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Request ID <SortingArrow onSort={handleSort} field="task_id"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Movement type <SortingArrow onSort={handleSort} field="movement_type"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Report date <SortingArrow onSort={handleSort} field="updated_at"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Amount <SortingArrow onSort={handleSort} field="amount"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                payment card number{" "}
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Transaction status
                <SortingArrow onSort={handleSort} field="payment_status"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          {motionListing?.rows?.length > 0 ? (
              motionListing.rows.map((motion, index) => (
            <tr key={index} className="bg-white border-b">
              <td className="px-3 py-4 vertical-top">#{motion?.transaction_id}</td>
              <td className="px-3 py-4 vertical-top">{moment(motion?.created_at).format('MM/DD/YYYY hh:mm A')}</td>
              <td className="px-3 py-4 vertical-top">{motion?.user?.first_name} {motion?.user?.last_name}</td>
              <td className="px-3 py-4 vertical-top">{motion?.user?.email}</td>
              <td className="px-3 py-4 vertical-top">{(motion?.id)?motion?.id:'-'}</td>
              <td className="px-3 py-4 vertical-top capitalize">{motion?.movement_type}</td>
              <td className="px-3 py-4 vertical-top">{(motion?.status==='complete')?moment(motion?.updated_at).format('MM/DD/YYYY hh:mm A'):'-'}</td>
              <td className="px-3 py-4 vertical-top">${motion?.amount}</td>
              <td className="px-3 py-4 vertical-top">{(motion?.payment_method?.[0]?.card) ? `************${motion?.payment_method?.[0].card}` : '-'}</td>
              <td className="px-3 py-4 vertical-top">
              <p className={`text-xs font-medium bg-[#01940033] rounded-lg text-center p-2 capitalize ${
                      motion?.payment_status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                      (motion?.payment_status === 'succeeded') ? 'text-[#019400] bg-[#01940033]' : 'text-[#DC362E] bg-[#DC362E40]'
                    }`}>
                  {motion?.payment_status}
                </p>
              </td>
              <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                <Tooltip title="Download invoice">
                  <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" onClick={()=>exportPDF(motion?.id)} />
                </Tooltip>
              </td>
            </tr>
            ))
            ) : (
              <tr>
                <td colSpan={10} className="text-center px-3 py-4 text-gray-500">
                  No records found
                </td>
              </tr>
            )}
            {/* <tr className="bg-white border-b">
              <td className="px-3 py-4 vertical-top">#123456</td>
              <td className="px-3 py-4 vertical-top">09/29/2024 03:00 PM</td>
              <td className="px-3 py-4 vertical-top">John</td>
              <td className="px-3 py-4 vertical-top">John@gmail.com</td>
              <td className="px-3 py-4 vertical-top">12345678</td>
              <td className="px-3 py-4 vertical-top"></td>
              <td className="px-3 py-4 vertical-top">10/30/2024</td>
              <td className="px-3 py-4 vertical-top">$1234</td>
              <td className="px-3 py-4 vertical-top"></td>
              <td className="px-3 py-4 vertical-top">
                <p className="text-xs text-[#faad14] font-medium bg-[#e8e62047] rounded-lg text-center p-2">
                  Processing
                </p>
              </td>
              <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                <Tooltip title="Download invoice">
                  <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" />
                </Tooltip>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-3 py-4 vertical-top">#123456</td>
              <td className="px-3 py-4 vertical-top">09/29/2024 03:00 PM</td>
              <td className="px-3 py-4 vertical-top">John</td>
              <td className="px-3 py-4 vertical-top">John@gmail.com</td>
              <td className="px-3 py-4 vertical-top">12345678</td>
              <td className="px-3 py-4 vertical-top"></td>
              <td className="px-3 py-4 vertical-top">10/30/2024</td>
              <td className="px-3 py-4 vertical-top">$1234</td>
              <td className="px-3 py-4 vertical-top">***************6789</td>
              <td className="px-3 py-4 vertical-top">
                <p className="text-xs text-[#DC362E] font-medium bg-[#DC362E40] rounded-lg text-center p-2">
                  Failed
                </p>
              </td>
              <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                <Tooltip title="Download invoice">
                  <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" />
                </Tooltip>
              </td>
            </tr> */}
            {/* Manage developer side */}
            {/* <tr>
            <td colSpan={11} className="text-center">
              <EmptyComponent />
            </td>
          </tr> */}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center pt-5">
          <div className="font-semibold text-base">
            Total count : <span>{motionListing?.count}</span>
          </div>
          {motionListing?.count > 10 && (
          <PaginationComponent
            currentPage={filters.page}
            total={motionListing?.count}
            pageSize={filters.limit}
            onPageChange={handlePageChange} />
          )}
        </div>
    </>
  );
};
export default MotionAIReportGeneration;
