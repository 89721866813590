import { useState, useEffect } from "react";
import { Tooltip, Select, Button, Card, Typography } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import SortingArrow from "../../Common/SortingArrow";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import PaginationComponent from "../../Common/Pagination";
import CoachService from "../../service/CoachService";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";

const status = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
  {
    value: "pending",
    label: "Pending",
  },
];
const CoachSer = new CoachService();
const ManageCoach = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [coachListing, setCoachListing] = useState([]);
  const { Search } = Input;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteId, setIsDeleteId] = useState();
  const [searchData, setSearchData] = useState("");
  const [collegeList, setCollegeList] = useState({});
  const [collegeFilter, setCollegeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState();
  const [filters, setFilters] = useState({ page: 1, search: '', college_id: '', status: '', limit:10,sort:'',order:'' });

  useEffect(() => {
    const filterCoaches = async () => {
      try{
      setLoading(true);
      const response = await CoachSer.getCoachList(filters);
      setCoachListing(response?.data);
      setLoading(false);
      }catch(error){
        let msg = CoachSer.errorMessage(error);
        showAlert(msg, "error");
      }
    };
    
    filterCoaches();
  }, [filters]);

  useEffect(() => {
    const fetchCollegeData = async () => {
      try {
        const response = await CoachSer.getCollegeList();
        if (response?.data) {
          const collegeArray = response.data.map(clg => ({
            value: clg.id,
            label: clg.name
          }));
          setCollegeList(collegeArray);
        }
      } catch (error) {
        console.error("Failed to fetch college data:", error);
      }
    };
    fetchCollegeData();
  }, []);
  
  const handleDeleteOk =async () => {
    try {
    const response = await CoachSer.deleteCoach(isDeleteId);
    setIsDeleteModalOpen(false);
    setCoachListing((prev) => ({
      ...prev,
      rows: prev.rows.filter((coach) => coach.id !== isDeleteId),
      count: prev.count - 1,
    }));
    showAlert(response?.message, "success");
    } catch (error) {
      let msg = CoachSer.errorMessage(error);
      showAlert(msg, "error");
    }
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeletePopupClick = (id) => {
    setIsDeleteModalOpen(true)
    setIsDeleteId(id);
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchData(value);
    if (value.length > 2) {
      setFilters({ ...filters, page:1, search: value });
    } else if (value.length === 0) {
      setFilters({ ...filters, page:1, search: '' });
    }
  };
  const handleCollegeChange = (value) => {
    let jsonString = "";
    if(value?.length !== 0)
     jsonString = JSON.stringify(value);
    
    setCollegeFilter(jsonString);
    setFilters({ ...filters, page:1, college_id: jsonString });
  };
  const handleStatusChange = (value) => {
    setStatusFilter(value);
    setFilters({ ...filters, page:1, status: value });
  };
  const clearFilter = ()=>{
    setSearchData("");
    setCollegeFilter("");
    setStatusFilter();
    setFilters({ ...filters, page: 1, search: '', college_id: '', status: '', limit:10,sort:'',order:'' });
  }
  const handlePageChange = (page, size) => {
    console.log(page, "---", size)
    setFilters({ ...filters, page, limit: size });
  };
  const handleResendMailClick = async (id) =>{
    try {
      const response = await CoachSer.resendCoachMail(id);
      showAlert(response?.message, "success");
      } catch (error) {
        let msg = CoachSer.errorMessage(error);
        showAlert(msg, "error");
      }
  }
  const handleSort = (field, key) => {
    setFilters({ ...filters, page:1, sort:field, order: key });
  };
  const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  return (
    <>
      <Card>
        <div className="flex justify-between	mb-4 gap-5">
          <Typography className="text-xl font-semibold" align={"left"}>
            Manage Coach
          </Typography>
          <div>
          <Button
            type="primary"
            size={14}
            className="mx-1.5 cursor-pointer"
            icon={<PlusOutlined />}
            onClick={() => navigate("/create-coach")}
          >
            Add Coach
          </Button>
          </div>
        </div>
        <div className="flex justify-end mb-4 gap-3">
          <Search
            placeholder="Search coach by Name or Email ID..."
            value={searchData}
            style={{
              width: 300,
            }}
            className="cursor-pointer"
            onChange={handleSearchChange}
          />
          <Select
            maxTagCount="responsive"
            mode="multiple"
            allowClear
            style={{
              width: 400,
            }}
            placeholder="College Name"
            filterOption={filterOption}
            options={collegeList}
            className="cursor-pointer"
            value={collegeFilter ? JSON.parse(collegeFilter) : []}
            onChange={handleCollegeChange}
          />
          <Select
            style={{
              width: 150,
            }}
            placeholder="Status"
            options={status}
            className="cursor-pointer"
            value={statusFilter}
            onChange={handleStatusChange}
            showSearch={false}
            filterOption={false}
          />
          <Button type="link" size={14} className="cursor-pointer" onClick={clearFilter}>
            Clear Filter
          </Button>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full  text-left	 text-sm  text-gray-500">
            <thead className="text-xs text-gray-700 bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  Name <SortingArrow onSort={handleSort}  field="first_name"/>
                </th>
                {/* onClick={() => handleSort('name')} */}
                <th scope="col" className="px-3 py-3">
                  Title <SortingArrow onSort={handleSort} field="current_position"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Email ID <SortingArrow onSort={handleSort} field="email_address"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  College name <SortingArrow onSort={handleSort} field="college_name"/>
                </th>
                <th scope="col" className="px-3 py-3 text-center">
                  Status <SortingArrow onSort={handleSort} field="status"/>
                </th>
                <th scope="col" className="text-center px-3 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            {coachListing?.rows?.length > 0 ? (
              coachListing.rows.map((coach, index) => (
               <tr key={index} className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                    onClick={() => navigate(`/coach-detail/${coach.id}`)}
                  >
                    {" "}
                    {coach?.first_name} {coach?.last_name}
                  </th>
                  <td className="px-3 py-4 vertical-top">{coach?.current_position}</td>
                  <td className="px-3 py-4 vertical-top">{coach?.email_address}</td>
                  <td className="px-3 py-4 vertical-top">{coach?.college?.name}</td>
                  <td className="px-3 py-4 vertical-top">
                  <p
                    className={`text-xs font-medium bg-[#01940033] rounded-lg text-center p-2 ${
                      coach?.status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                      (coach?.status === 'rejected' || coach?.status === 'inactive' ) ? 'text-[#DC362E] bg-[#DC362E40]' : 'text-[#019400] bg-[#01940033]'
                    }`}>
                      {coach?.status}
                    </p>
                  </td>
                  <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                    <Tooltip title="Edit">
                      <EditOutlined
                        className="mx-1.5 cursor-pointer"
                        onClick={() => navigate(`/create-coach/${coach.id}`)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <DeleteOutlined
                        className="mx-1.5 cursor-pointer"
                        onClick={() => handleDeletePopupClick(coach.id)}
                      />
                    </Tooltip>
                    {coach?.status === 'pending' && (
                      <Tooltip title="Resend Email">
                        <SendOutlined className="mx-1.5 cursor-pointer" 
                        onClick={() => handleResendMailClick(coach.id)}/>
                      </Tooltip>
                    )}
                  </td>
                </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center px-3 py-4 text-gray-500">
                      No records found
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-semibold text-base">
            Total count : <span>{coachListing?.count}</span>
          </div>
          {coachListing?.count > 10 && (
          <PaginationComponent
            currentPage={filters.page}
            total={coachListing?.count}
            pageSize={filters.limit}
            onPageChange={handlePageChange} />
          )}
        </div>
      </Card>
      {/* delete modal */}
      <ConfirmationModal
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this Coach?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};
export default ManageCoach;
