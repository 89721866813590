import { Card, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ViewFreePlanSubscriberDetails from "./ViewSubscriptionDetailsTab/ViewFreePlanSubscriberDetails";
const ViewSubscriptionDetails = () => {
  const navigate = useNavigate();
  return (
    <>
      <Card>
        <div className="flex justify-between	mb-4 gap-5">
          <div className="">
            <Typography className="text-xl font-semibold" align={"left"}>
              <LeftOutlined
                className="text=lg text-black mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              Subscription Plan Details
            </Typography>
          </div>
        </div>
        <div className="relative mt-6">
              <ViewFreePlanSubscriberDetails />
        </div>
      </Card>
    </>
  );
};
export default ViewSubscriptionDetails;