import {API_PLAN_SECTION} from "../constants/apiEndPoints";
import CoreAPI from "./CoreAPI";

class PlanService extends CoreAPI {

/**
  *
  * @param  {*}
  * @returns
  */
  getPlanList(filter){
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_PLAN_SECTION.GET_PLAN_LIST,filter);
  }


  /**
   *
   * @param {*} Id
   * @returns
   */

  getPlanDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_PLAN_SECTION.GET_PLAN_DETAILS+id);
  }
  
  updatePlanStatus(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_PLAN_SECTION.UPDATE_PLAN_STATUS,body);
  }

  updatePlanDetails(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_PLAN_SECTION.UPDATE_PLAN_DETAILS,body);
  }

  getPlanSubscriptionList(id,filter){
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_PLAN_SECTION.GET_PLAN_SUBCCRIPTION_LIST+id,filter);
  }

}

export default PlanService;
