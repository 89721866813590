import { S3Client, DeleteObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { Config } from "../constants/Config";

// Configure AWS with environment variables
const s3 = new S3Client({
  region: Config.s3Region,
  credentials: {
    accessKeyId: Config.appAccessKey,
    secretAccessKey: Config.s3SecretKey,
  },
});

export const addObject = async (key, body, bucketName=Config.s3BucketName) => {
  console.log("sdsdssdsd");
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: body,
    ContentType: body?.type,
    ACL: "public-read",
  };

  try {
    const command = new PutObjectCommand(params);
    const response = await s3.send(command);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteObject = async (key, bucketName=Config.s3BucketName) => {
  const params = {
    Bucket: bucketName,
    Key: key,
  };
  try {
    const command = new DeleteObjectCommand(params);
    const response = await s3.send(command);
    return response;
  } catch (error) {
    throw error;
  }
};

export default s3;
