import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Button } from "antd";
import MotionService from "../../service/MotionService";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";

const MotionSer = new MotionService();

const ViewSetPrice = ({ isOpen, onOk, onCancel }) => {
  const { showAlert } = useAlert();
  const [form] = Form.useForm();
  const { setLoading } = useLoader();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await MotionSer.getPriceData();
        form.setFieldsValue(response?.data[0]);
      } catch (error) {
        console.error("Failed to fetch college data:", error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await MotionSer.updatePrice({ ...values, id: 1 });
      showAlert("Price updated successfully!", "success");
      onOk();
    } catch (error) {
      showAlert(error.message, "error");
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        title="View/Set Price"
        centered
        open={isOpen}
        onCancel={onCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isDisabled}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        ]}
      >
        <p>
          This price or rate applies to athletes for the report generation
          service that they are required to pay.
        </p>
        <Form
          form={form}
          layout="vertical"
          style={{ marginTop: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleSubmit}
          onFinishFailed={(errorInfo) => {
            showAlert("Please fix the errors in the form.", "error");
          }}
        >
          <Form.Item
                label="Price"
                name="price"
                rules={[
                  { 
                    required: true, 
                    message: "Please enter your Price" 
                  },
                  { 
                    pattern: /^(\d+(\.\d{1,2})?)?$/, 
                    message: "Please enter a valid price" 
                  }
                ]}
              >
            <Input
              size="large"
              maxLength={6}
              type="number"
              step="0.01"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ViewSetPrice;
