import {API_MOTION_SECTION} from "../constants/apiEndPoints";
import CoreAPI from "./CoreAPI";

class MotionService extends CoreAPI {


  /**
  *
  * @param  {*}
  * @returns
  */
  getPriceData() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_PRICE_DETAIL);
  }

  /**
   *
   * @param {*} body
   * @returns
   */

  updatePrice(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_MOTION_SECTION.UPDATE_PRICE_DETAIL,body);
  }
  
  /**
  *
  * @param  {*}
  * @returns
  */
  getMomentTypeList() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_MOMENT_TYPE_LIST);
  }


  /**
  *
  * @param  {*}
  * @returns
  */
  getMotionList(filter) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_MOTION_LIST,filter);
  }

  
  /**
   *
   * @param {*} Id
   * @returns
   */

  getMotionDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_MOTION_DETAILS+id);
  }

  getSubscriptionList(filter) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_SUBCRIPTION_LIST,filter);
  }

  getExportMotionList() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_EXPORT_LIST);
  }

  getAthleteList() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_ATHLETE_LIST);
  }

  getMotionPdf(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_PDF_INVOICE+id);
  }

  getExportSubscription() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_MOTION_SECTION.GET_EXPORT_SUBSCRIPTION);
  }
 
}

export default MotionService;
