import {
  Card,
  Typography,
  DatePicker,
  Input,
  Tabs,
  Select,
  Button,
} from "antd";
import { useState, useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";
import SubscriptionPlan from "./ManageTransactionTabs/SubscriptionPlan";
import MotionAIReportGeneration from "./ManageTransactionTabs/MotionAIReportGeneration";
import MotionService from "../../service/MotionService";
import moment from 'moment';
const MotionSer = new MotionService();
const { RangePicker } = DatePicker;
const ManageTransaction = () => {
  const [athleteListing, setAthleteListing] = useState([]);
  const navigate = useNavigate();
  const [dates, setDates] = useState([null, null]);
  const [startDate, endDate] = dates;
  const [activeTab, setActiveTab] = useState("1");
  const [athleteFilter, setAthleteFilter] = useState("");
  const [filters, setFilters] = useState({ page: 1, userId: '', start_date: '', end_date: '', limit: 15, sort: '', order: '' });
  const [rangePickerValue, setRangePickerValue] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
    const response = await MotionSer.getAthleteList();
    const transformedData = response?.data.map((athlete) => ({
      value: athlete.id.toString(),
      label: `${athlete.first_name} ${athlete.last_name}`,
    }));
    setAthleteListing(transformedData);
    };
    fetchData();
  }, []);
  const handleAthleteChange = (value) => {
    let jsonString = "";
    if (value?.length !== 0) jsonString = JSON.stringify(value);
    setAthleteFilter(jsonString);
    setFilters(prevFilters => ({ ...prevFilters, page: 1, userId: jsonString }));
  };
  const handleTabChange = (key) => {
    setFilters({ page: 1, userId: '', start_date: '', end_date: '', limit: 15, sort: '', order: ''});
    setAthleteFilter("");
    setRangePickerValue(null);
    setActiveTab(key);
  };
  const handleDateChange = (dates) => {
    if (dates) {
      const startDate = dates[0] ? dates[0].format('YYYY-MM-DD') : '';
      const endDate = dates[1] ? dates[1].format('YYYY-MM-DD') : '';
      setFilters({
        ...filters,
        start_date: startDate,
        end_date: endDate,
      });
      setRangePickerValue(dates);
    } else {
      setFilters({
        ...filters,
        start_date: '',
        end_date: '',
      });
      setRangePickerValue(null);
    }
  };
  const handleClearFilter = () => {
    setAthleteFilter("");
    setRangePickerValue(null);
    setFilters({ page: 1, userId: '', start_date: '', end_date: '', limit: 10, sort: '', order: ''});
  }
  const disableFutureDates = (current) => {
    // Disable dates after today
    return current && current > new Date();
  };

  const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <>
      <Card>
        <div className="flex justify-between	mb-4 gap-5">
          <div className="">
            <Typography className="text-xl font-semibold" align={"left"}>
              Transaction History
            </Typography>
          </div>
          <div className="flex justify-end flex-wrap gap-3">
            <Select
              style={{
                width: 300,
              }}
              maxTagCount="responsive"
              placeholder="Athlete name"
              options={athleteListing}
              value={athleteFilter ? JSON.parse(athleteFilter) : []}
              onChange={handleAthleteChange}
              filterOption={filterOption}
              mode="multiple"
              allowClear
              defaultValue="All Athlete"
              className="mx-1.5 cursor-pointer"
            />
            <div>
              <RangePicker format="MM/DD/YYYY" className="w-full" value={rangePickerValue} onChange={handleDateChange} disabledDate={disableFutureDates}/>
            </div>
            <Button type="link" size={14} className="cursor-pointer" onClick={handleClearFilter}>
              Clear Filter
            </Button>
          </div>
        </div>
        <div className="relative mt-6">
          <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab="Subscription Plan" key="1">
              <SubscriptionPlan filters={filters} setFilters={setFilters} activeTab={activeTab}/>
            </TabPane>
            <TabPane tab="3 motion AI Report Generation " key="2">
              <MotionAIReportGeneration filters={filters} setFilters={setFilters} activeTab={activeTab}/>
            </TabPane>
          </Tabs>
        </div>
      </Card>
    </>
  );
};
export default ManageTransaction;
