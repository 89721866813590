import React, { useState } from "react";
import { Typography, Button, Input, Form, Layout, Card } from "antd";
import { Link } from "react-router-dom";
import AuthService from "../../../service/AuthService";
import { useAlert } from "../../../contexts/AlertProvider";

const { Title } = Typography;
const AuthSer = new AuthService();

const ForgotPassword = () => {
  const { showAlert } = useAlert();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const [resetLink, setResetLink] = useState("");

  const handleSubmit = async (values) => {
    const { email } = values;

    setIsDisabled(true);
    try {
      const response = await AuthSer.forgotPassword(email);
      showAlert("A password reset link has been sent to your email address.", "success");
      if (response?.link) {
        setResetLink(response?.link);
      }
      form.resetFields();
    } catch (error) {
      let msg = AuthSer.errorMessage(error);
      showAlert(msg, "error");
    }
    setIsDisabled(false);
  };

  return (
    <>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <Card className="relative">
            <img
              width={450}
              src="logo.png"
              alt=""
              className="mx-auto absolute top-[-90px] left-0 right-0"
            />
            <Title level={3} align="center">
              Forgot Password
            </Title>
            <Form
              form={form}
              layout="vertical"
              style={{
                maxWidth: 400,
                minWidth: 400,
                marginTop: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              <Form.Item
                label="Email address"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email address.",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Button
                className="w-full mt-4"
                size="large"
                type="primary"
                htmlType="submit"
                disabled={isDisabled}
              >
                Submit
              </Button>
            </Form>
            <div className="flex items-center justify-center mt-4">
              <h5>Go back to</h5>
              <Link to="/">
                &nbsp;
                <Button className="p-0 font-semibold" type="link">
                  Login
                </Button>
              </Link>
            </div>
            {resetLink &&
            <div className="flex items-center justify-center mt-4">
              <h5>Go reset link</h5>
              <Link to={resetLink}>
                &nbsp;
                <Button className="p-0 font-semibold" type="link">
                  Reset Page
                </Button>
              </Link>
            </div>
            }
          </Card>
        </div>
      </Layout>
    </>
  );
};

export default ForgotPassword;
