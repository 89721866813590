import { useState, useEffect } from "react";
import { Card, Col, Collapse, Row, Image } from "antd";
import { useParams } from "react-router-dom";
import { STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import AthleteService from "../../../service/AthleteService";
import { Config } from "../../../constants/Config";
import { decodeId } from '../../../utils/encoding';

const AthleteSer = new AthleteService();

const SportsDetails = () => {
  const { id } = useParams();
  const [sportsDetails, setSportsDetails] = useState([]);

  useEffect(() => {
    const fetchProfileData = async () => {
      const athleteId = decodeId(id);
      let sports = addDeleteGetLocalStorage(STORAGE.SPORTS, {}, "get", "single");
      const response = await AthleteSer.getAthleteSportsDetails(sports, athleteId);
      setSportsDetails(response?.data);
    };
    fetchProfileData();
  }, [id]);

  addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");

  return (
    <>
      <Card>
        <div>
          <div className="flex justify-between items-center mb-3">
            <div className="font-semibold text-base">Provide sport details</div>
          </div>
          <div className="flex flex-col gap-4">
            <Collapse
              collapsible="header"
              expandIconPosition="right"
              items={sportsDetails?.map(item => ({
                key: item.key,
                label: (
                  <div className="flex gap-3 items-center text-[#1D2137] text-sm font-semibold">
                    <img
                      src={`${Config.imagePATH}${item.image}`}
                      alt="college-banner"
                      className="h-12 w-12 object-cover rounded overflow-hidden"
                    />
                    {item.name}
                  </div>
                ),
                children: (
                  <Row>
                    {item?.fields?.map((val, index) => (
                      <Col key={index} span={10}>
                        <div className="mr-4 my-5">
                          <div className="font-semibold text-[17px]">
                            {val.field_label}
                          </div>
                          <div className="text-sm">{(val?.sports_fields_values[0]?.field_value)?(val?.sports_fields_values[0]?.field_value):'-'} {val?.sports_fields_values[0]?.other_value}</div>
                        </div>
                      </Col>
                    ))}
                    <Col span={20}>
                        <div className="mr-4 my-5">
                          <div className="font-semibold text-[17px]">
                            Recruiting Profile Link:
                          </div>
                          {item?.sportsData?.recruit_profile_link?.length > 0 ? (
                          item?.sportsData?.recruit_profile_link?.map((link, index) => (
                            <div key={index} className="text-sm">
                              <span className="font-semibold">Link {index+1} :</span>{" "}
                              {link}
                            </div>
                          ))
                          ) : (
                            "-"
                          )}
                        </div>
                      </Col>
                      <Col span={20}>
                        <div className="mr-4 my-5">
                          <div className="font-semibold text-[17px]">
                            Travel Schedule:
                          </div>
                          {item?.sportsData?.travel_schedule?.length > 0 ? (
                          item?.sportsData?.travel_schedule?.map((image, index) => (
                          <div key={index} className="w-full  max-w-[110px] h-[110px] rounded-lg overflow-hidden mt-2">
                            <Image
                              width={110}
                              src={image}
                            />
                          </div>
                          ))
                          ) : (
                            "-"
                          )}
                        </div>
                      </Col>
                      <Col span={20}>
                        <div className="mr-4 my-5">
                          <div className="font-semibold text-[17px]">
                            Analytical Data:
                          </div>
                          {item?.sportsData?.analytics_data?.length> 0 ? (
                          item?.sportsData?.analytics_data?.map((image, index) => (
                          <div key={index} className="w-full  max-w-[110px] h-[110px] rounded-lg overflow-hidden mt-2">
                            <Image
                              width={110}
                              src={image}
                            />
                          </div>
                           ))
                           ) : (
                             "-"
                           )}
                        </div>
                      </Col>
                      <Col span={20}>
                        <div className="mr-4 my-5">
                          <div className="font-semibold text-[17px]">
                            Stat Verification MAXPREPS Link:
                          </div>
                          {item?.sportsData?.maxpreps_link?.length > 0 ? (
                           item?.sportsData?.maxpreps_link?.map((link, index) => (
                          <div className="text-sm">
                          <span className="font-semibold">Link {index+1} :</span> {link}
                          </div>
                          ))
                          ) : (
                            "-"
                          )}
                        </div>
                      </Col>
                  </Row>
                ),
              }))}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default SportsDetails;
