"use client";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    const storedToken = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");
    if (storedToken) {
      setToken(storedToken);
      setDecodedToken(jwtDecode(storedToken));
    }
  }, []);

  const saveToken = (newToken) => {
    setToken(newToken);
    const decoded = jwtDecode(newToken);
    setDecodedToken(decoded);
    addDeleteGetLocalStorage(STORAGE.TOKEN, newToken, "add", "single");
  };

  const clearToken = () => {
    setToken(null);
    setDecodedToken(null);
    addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "delete", "single");
  };

  return (
    <TokenContext.Provider value={{ token, decodedToken, saveToken, clearToken }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => {
  return useContext(TokenContext);
};
