import React, { useState } from "react";
import { Form, Input, Modal, Button } from "antd";
import AuthService from "../../service/AuthService";
import { useToken } from "../../contexts/TokenProvider";
import { useAlert } from "../../contexts/AlertProvider";

const AuthSer = new AuthService();

const ChangePassword = ({ isOpen, onOk, onCancel }) => {
  const { saveToken } = useToken();
  const { showAlert } = useAlert();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (values) => {
    const { oldPassword, newPassword, confirmPassword } = values;

    setIsDisabled(true);
    try {
      let result = await AuthSer.changePassword(oldPassword, newPassword, confirmPassword);
      if (result.token) {
        saveToken(result.token);
      }
      showAlert("Password changed successfully!", "success");
      onOk();
      form.resetFields();
    } catch (error) {
      let msg = AuthSer.errorMessage(error);
      showAlert(msg, "error");
    }
    setIsDisabled(false);
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter your new password."));
    }

    if (value.length < 10 || value.length > 50) {
      return Promise.reject(new Error("Password must be between 10 and 50 characters."));
    }

    const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=]{10,50}$/;
    if (!pattern.test(value)) {
      return Promise.reject(new Error("Password must contain at least one letter and one number."));
    }

    return Promise.resolve();
  };

  return (
    <>
      <Modal
        title="Change Password"
        centered
        open={isOpen}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isDisabled}
            onClick={() => form.submit()}
          >
            Change Password
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Please enter your old password.",
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Confirm passwords do not match.'));
                },
              }),
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePassword;
