import { useState, useEffect } from "react";
import { Card, Col, Divider, Row, Tooltip, Typography } from "antd";
import { LeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import MotionService from "../../service/MotionService";
import { useAlert } from "../../contexts/AlertProvider";
import moment from "moment";

const MotionSer = new MotionService();

const RequestDetailPage = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { id } = useParams();
  const [motionDetails, setMotionDetails] = useState([]);
  
  useEffect(() => {
    if (id) {
      const fetchProfileData = async () => {
        const response = await MotionSer.getMotionDetails(id);
        setMotionDetails(response?.data);
      };
      fetchProfileData();
    }
  }, [id]);

  const handleDownloadVideo = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      showAlert("No Video to download", "error");
    }
  };
  const handleDownloadPdf = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      showAlert("No PDF to download", "error");
    }
  };

  return (
    <>
      <Card>
        <div className="flex justify-between	mb-4 gap-5">
          <div className="">
            <Typography className="text-xl font-semibold" align={"left"}>
              <LeftOutlined
                className="text=lg text-black mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              Request Detail
            </Typography>
          </div>
          {/* <Tooltip title="Export">
            <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" />
          </Tooltip> */}
        </div>
        <Divider />
        <Row gutter={[40, 16]} className="mt-3 mb-6">
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Request ID :{" "}
            </div>
            <div className="text-base">#{motionDetails?.id}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Request date :{" "}
            </div>
            <div className="text-base">{moment(motionDetails.created_at).format("MM/DD/YYYY")}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Report date :{" "}
            </div>
            <div className="text-base">{motionDetails?.status === "complete"
                            ? moment(motionDetails.updated_at).format("MM/DD/YYYY")
                            : "-"}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Athlete name :{" "}
            </div>
            <div className="text-base">{motionDetails?.user?.first_name} {motionDetails?.user?.last_name}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Email address :{" "}
            </div>
            <div className="text-base">{motionDetails?.user?.email}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Movement type :{" "}
            </div>
            <div className="text-base">{motionDetails?.movement_type}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Amount :{" "}
            </div>
            <div className="text-base">${motionDetails?.amount}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Payment method :{" "}
            </div>
            <div className="text-base">{motionDetails?.payment_method?.[0].brand}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Payment date & time:{" "}
            </div>
            <div className="text-base">{moment(motionDetails?.created_at).format('MM/DD/YYYY hh:mm A')}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Status :{" "}
            </div>
            <div className="text-base">
            <p className={`w-[100px] text-xs font-medium rounded-lg text-center p-2 capitalize ${
                      motionDetails?.status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                      (motionDetails?.status === 'error') ? 'text-[#DC362E] bg-[#DC362E40]' : 'text-[#019400] bg-[#01940033]'
                    }`}>
              {motionDetails?.status}
              </p>
              {/* Manage developer side */}
              {/* <p className="text-xs text-[#faad14] font-medium bg-[#e8e62047] rounded-lg text-center p-2">
                  Pending
                  </p>
                  <p className="text-xs text-[#DC362E] font-medium bg-[#DC362E40] rounded-lg text-center p-2">
                  Error
                  </p> */}
            </div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Refund Status :{" "}
            </div>
            <div className="text-base">{(motionDetails?.refund_status)?motionDetails?.refund_status:'-'}</div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
            Refund Requested (date & time) :{" "}
            </div>
            <div className="text-base">
            
            {(motionDetails?.refund_data?.created)?(moment.unix(motionDetails?.refund_data?.created).format('MM/DD/YYYY hh:mm A')):'-'}
            </div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="text-base font-medium text-black w-[250px]">
              Video  :{" "}
            </div>
            <div className="flex items-center gap-2">
              {/* <img
                src="images/video.gif"
                alt="college-banner"
                className=" h-[100px] w-[100px] object-cover rounded overflow-hidden"
              /> */}
              {motionDetails?.status === "complete" ? (
              <Tooltip title="Download Video">
                <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" onClick={() => handleDownloadVideo(motionDetails?.video_url)}/>
              </Tooltip>
              ) : (
                <span>-</span>
              )}
            </div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
          <div className="text-base font-medium text-black w-[250px]">
              PDF  :{" "}
            </div>
            <div className="flex items-center gap-2">
              {/* <img
                src="images/video.gif"
                alt="college-banner"
                className=" h-[100px] w-[100px] object-cover rounded overflow-hidden"
              /> */}
              {motionDetails?.status === "complete" ? (
              <Tooltip title="Download PDF">
                <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" onClick={() => handleDownloadPdf(motionDetails?.pdf_url)}/>
              </Tooltip>
              ) : (
                <span>-</span>
              )}
            </div>
            </Col> 
        </Row>
      </Card>
    </>
  );
};
export default RequestDetailPage;
