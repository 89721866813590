import { useState } from "react";
import { Layout, Menu } from "antd";
import {
  AiOutlineContainer,
  AiOutlineMenuUnfold,
  AiOutlineMenuFold,
} from "react-icons/ai";
import { MdOutlineSpaceDashboard, MdOutlineSubscriptions } from "react-icons/md";
import { VideoCameraOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { RiGraduationCapLine } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa6";
import { TbSettingsExclamation } from "react-icons/tb";
import { FaRunning } from "react-icons/fa";
import { Link } from "react-router-dom";
import SubMenu from "antd/es/menu/SubMenu";
import ChangePassword from "../../Modal/ChangePassword";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { useToken } from "../../contexts/TokenProvider";
const { Sider } = Layout;
const LeftSidebar = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const { decodedToken, clearToken } = useToken();
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const showChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };
  const handleChangePasswordOk = () => {
    setIsChangePasswordModalOpen(false);
  };
  const handleChangePasswordCancel = () => {
    setIsChangePasswordModalOpen(false);
  };
  const showLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };
  const handleLogoutOk = () => {
    setIsLogoutModalOpen(false);
    clearToken();
    navigate('/');
  };
  const handleLogoutCancel = () => {
    setIsLogoutModalOpen(false);
  };
  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
        width={250}
      >
        <div
          className={`logo flex justify-center h-[56px] my-4 transition-opacity duration-500 `}
        >
          {collapsed ? (
            // <img src="/logo-single.svg" alt="logo" className="" />
            <span
              style={{ color: "#fff", fontSize: "30px", fontWeight: "bold" }}
            >
              C
            </span>
          ) : (
            <img src="/lightLogo.png" alt="logo" className="" />
          )}
        </div>
        <Menu theme="dark" defaultSelectedKeys={"auto"} mode="inline">
          <Menu.Item
            key="1"
            icon={<MdOutlineSpaceDashboard className="!text-base" />}
          >
            <Link to="/dashboard">Dashboard </Link>
          </Menu.Item>
          {/* <Menu.Item
            key="2"
            icon={<MdOutlineCategory className="!text-base" />}
          >
            <Link to="/manage-category">Manage Category</Link>
          </Menu.Item>
          <Menu.Item
            key="3"
            icon={<DiHtml5Multimedia className="!text-base" />}
          >
            <Link to="/manage-media-blog">Manage Media/Blog</Link>
          </Menu.Item> */}
          <Menu.Item key="4" icon={<FaRunning className="!text-base" />}>
            <Link to="/manage-athlete">Manage Athlete</Link>
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={<RiGraduationCapLine className="!text-base" />}
          >
            <Link to="/manage-college">Manage Colleges</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<FaRegUser className="!text-base" />}>
            <Link to="/manage-coach">Manage Coach</Link>
          </Menu.Item>
          {/* <SubMenu
            key="7"
            icon={<CgMenuBoxed className="!text-base" />}
            title="CMS"
          >
            <Menu.Item key="11">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Menu.Item>
            <Menu.Item key="12">
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </Menu.Item>
          </SubMenu> */}
          <Menu.Item
            key="8"
            icon={<MdOutlineSubscriptions className="!text-base" />}
          >
            <Link to="/manage-subscription-plan">Manage Subscription Plan</Link>
          </Menu.Item>
          <Menu.Item
            key="9"
            icon={<MdOutlineSubscriptions className="!text-base" />}
          >
            <Link to="/manage-transaction">Manage Transaction</Link>
          </Menu.Item>
          <Menu.Item
            key="10"
            icon={<AiOutlineContainer className="!text-base" />}
          >
            <Link to="/motion-report-generation">3 Motion Report Generation</Link>
          </Menu.Item>
          <Menu.Item
            key="12"
            icon={<VideoCameraOutlined className="!text-base" />}
          >
            <Link to="/marketing-videos">Marketing Videos</Link>
          </Menu.Item>
          <SubMenu
            key="11"
            icon={<TbSettingsExclamation className="!text-base" />}
            title="Account settings"
          >
            <Menu.Item key="21">
              <Link to="/manage-profile">Manage Profile</Link>
            </Menu.Item>
            <Menu.Item key="22">
              <Link onClick={showChangePasswordModal}>Change Password</Link>
            </Menu.Item>
            <Menu.Item key="23">
              <Link onClick={showLogoutModal}>Logout</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
        <div className="absolute top-[14px] right-[-42px] text-black text-xl">
          {collapsed ? (
            <AiOutlineMenuUnfold
              className="toggle-icon"
              onClick={toggleCollapsed}
            />
          ) : (
            <AiOutlineMenuFold
              className="toggle-icon"
              onClick={toggleCollapsed}
            />
          )}
        </div>
      </Sider>
      <ChangePassword
        isOpen={isChangePasswordModalOpen}
        onOk={handleChangePasswordOk}
        onCancel={handleChangePasswordCancel}
      />
      <ConfirmationModal
        ConfirmationHeading="Logout"
        ConfirmationParagraph="Are you sure you want to logout?"
        isOpen={isLogoutModalOpen}
        onOk={handleLogoutOk}
        onCancel={handleLogoutCancel}
      />
    </>
  );
};
export default LeftSidebar;
