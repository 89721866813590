import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Switch, Tooltip } from "antd";
import SortingArrow from "../../../Common/SortingArrow";
import { DownloadOutlined } from "@ant-design/icons";
import PlanService from "../../../service/PlanService";
import PaginationComponent from "../../../Common/Pagination";
import { useAlert } from "../../../contexts/AlertProvider";
import moment from 'moment';
const PlanSer = new PlanService();
const ViewFreePlanSubscriberDetails = () => {
  const { id } = useParams();
  const [planData, setPlanData] = useState();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [subscriptionListing, setSubscriptionListing] = useState([]);
  const [filters, setFilters] = useState({ sort:'',order:'' });
  const [isActive, setIsActive] = useState(false);
  const handleSort = (field, key) => {
    setFilters({ ...filters, sort:field, order: key });
  };
  useEffect(() => {
    if (id) {
      const fetchProfileData = async () => {
        const response = await PlanSer.getPlanDetails(id);
        setPlanData(response?.data);
        //setSubscriptionListing(response?.data?.subscriptions);
        setIsActive((response?.data?.status ==="active")?true:false);
      };
      fetchProfileData();
    }
  }, [id]);

  useEffect(() => {
    const filterSubscription = async () => {
      try{
      const response = await PlanSer.getPlanSubscriptionList(id,filters);
      setSubscriptionListing(response?.data);
      }catch(error){
        let msg = PlanSer.errorMessage(error);
        showAlert(msg, "error");
      }
    };
    
    filterSubscription();
  }, [id, filters]);

  const handlePageChange = (page, size) => {
    console.log(page, "---", size)
    setFilters({ ...filters, page, limit: size });
  };
  
  const downloadPdf = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      showAlert("No Invoice to download", "error");
    }
  };
  const handleToggle = async (checked) => {
    try {
      let status = (checked)?'active':'inactive';
      let body={
        id, status
      }
      const response = await PlanSer.updatePlanStatus(body);
      setIsActive(checked);
      showAlert(response?.message, "success");
    } catch (error) {
      navigate('/manage-coach');
      let msg = PlanSer.errorMessage(error);
      showAlert(msg, "error");
    }
  };
  return (
    <>
      <Row gutter={[40, 16]} className="mt-3 mb-6">
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
            Plan Name :{" "}
          </div>
          <div className="text-base">{planData?.name}</div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
            Created date :{" "}
          </div>
          <div className="text-base">{moment(planData?.created_at).format('MM/DD/YYYY')}</div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
            Amount :{" "}
          </div>
          <div className="text-base">${planData?.amount}</div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
            Search College Limit:{" "}
          </div>
          <div className="text-base">{planData?.search_restrict}</div>
        </Col>

        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
            Upload video limit:{" "}
          </div>
          <div className="text-base">{planData?.video_restrict}</div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
            Upload Image limit:{" "}
          </div>
          <div className="text-base">{planData?.image_restrict}</div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
            Status:{" "}
          </div>
          <div className="text-base">
          <Switch checked={isActive} onChange={handleToggle} />{" "}
          </div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
          Sports Limit:{" "}
          </div>
          <div className="text-base">
            {planData?.search_restrict}{" "}
          </div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
          Contact Coach Limit:{" "}
          </div>
          <div className="text-base">
          {planData?.send_application_restrict}{" "}
          </div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="text-base font-medium text-black w-[300px]">
          Video overlay report per month limit:{" "}
          </div>
          <div className="text-base">
          {planData?.video_overlay_restrict}{" "}
          </div>
        </Col>
        <Col
          className="gutter-row flex items-start justify-start"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          {/* <div className="text-base font-medium text-black min-w-[300px]">
            Benefits:{" "}
          </div>
          <div className="text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div> */}
        </Col>

      </Row>
      <div className="relative overflow-x-auto">
        <table className="w-full  text-left	 text-sm  text-gray-500">
          <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Athlete Name
              </th>
              <th scope="col" className="px-3 py-3">
                Email Address
              </th>
              <th scope="col" className="px-3 py-3">
                Subs Start Date 
              </th>
              <th scope="col" className="px-3 py-3">
                Subs End Date
              </th>
              <th scope="col" className="px-3 py-3">
                Payment Information
              </th>
              <th scope="col" className="px-3 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          {subscriptionListing?.rows?.length > 0 ? (
            subscriptionListing?.rows?.map((sub, index) => (
            <tr key={index} className="bg-white border-b">
              <td className="px-3 py-4 vertical-top">{sub?.user?.first_name} {sub?.user?.last_name}</td>
              <td className="px-3 py-4 vertical-top">{sub?.user?.email}</td>
              <td className="px-3 py-4 vertical-top">{moment(sub?.start_date).format('MM/DD/YYYY')}</td>
              <td className="px-3 py-4 vertical-top">{(sub?.end_date)?moment(sub?.end_date).format('MM/DD/YYYY'):'-'}</td>
              <td className="px-3 py-4 vertical-top">${(sub?.amount)?sub?.amount:0}</td>
              <td className="px-3 py-4 vertical-top">
                {(sub?.pdf)?(
                <Tooltip title="pdf">
                  <DownloadOutlined className="mx-1.5 cursor-pointer" onClick={()=>downloadPdf(sub?.pdf)}/>
                </Tooltip>
                ):'-'}
              </td>
            </tr>
          ))
          ) : (
            <tr>
              <td colSpan={6} className="text-center px-3 py-4 text-gray-500">
                No records found
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center">
          <div className="font-semibold text-base">
            Total count : <span>{subscriptionListing?.count}</span>
          </div>
          {subscriptionListing?.count > 10 && (
          <PaginationComponent
            currentPage={filters.page}
            total={subscriptionListing?.count}
            pageSize={filters.limit}
            onPageChange={handlePageChange} />
          )}
        </div>
    </>
  );
};
export default ViewFreePlanSubscriberDetails;
