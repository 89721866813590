import { Image } from "antd";

const Alerts = ({ text, type }) => {
  const alertStyles = {
    success: {
      backgroundColor: "#B0ECB0",
      color: "#019400",
      icon: "/images/done.svg",
    },
    error: {
      backgroundColor: "#F8D7DA",
      color: "#D8000C",
      icon: "/images/cancel.svg",
    },
  };
  const style = alertStyles[type] || alertStyles.success;
  return (
    <div className="relative z-[9999999]">
      {/* Alert */}
      <div
        className="rounded text-sm font-medium fixed left-0 right-0 top-[24px] mx-auto w-full h-[40px] max-w-[450px] flex justify-center items-center"
        style={{ backgroundColor: style.backgroundColor, color: style.color }}
      >
        <Image
          src={style.icon}
          alt={`${type} Image`}
          width={18}
          height={18}
          
        />
        <h6 className="ml-[8px]">{text}</h6>
      </div>
    </div>
  );
};
export default Alerts;
