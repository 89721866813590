import React, { useState, useEffect } from "react";
import { Typography, Button, Input, Form, Layout, Card, Flex } from "antd";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../service/AuthService";
import { useToken } from "../../../contexts/TokenProvider";
import { useAlert } from "../../../contexts/AlertProvider";
import { useLoader } from "../../../contexts/LoadingProvider";

const { Title } = Typography;

const AuthSer = new AuthService();

const Login = () => {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const { decodedToken, saveToken } = useToken();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { setLoading } = useLoader();

  useEffect(() => {
    if(decodedToken){
      navigate('/dashboard');
    }
  }, [decodedToken]);

  const handleSubmit = async (values) => {
    const { email, password } = values;
    setLoading(true);
    setIsDisabled(true);
    
    try {
      let result = await AuthSer.signIn(email, password);
      saveToken(result?.data);
      navigate('/dashboard');
      form.resetFields();
    } catch (error) {
      let msg = AuthSer.errorMessage(error);
      showAlert(msg, "error");
      setIsDisabled(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Layout>
        <Flex
          gap="middle"
          align="center"
          justify="center"
          vertical
          style={{ minHeight: "100vh" }}
        >
          <Card className="relative">
            <img
              width={450}
              src="logo.png"
              alt=""
              className="mx-auto absolute top-[-90px] left-0 right-0"
            />
            <Title level={3} align={"center"}>
              Admin Login
            </Title>
            <Form
              form={form}
              layout="vertical"
              style={{
                maxWidth: 400,
                minWidth: 400,
                marginTop: 24,
              }}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              <Form.Item
                label="Email address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address.",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address.",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password.",
                  },
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
              <div className="text-right">
                <Link to="/forgot-password">
                  <Button className="p-0 font-semibold" type="link">
                    Forgot Password
                  </Button>
                </Link>
              </div>
              <Button
                className="w-full mt-4"
                size="large"
                type="primary"
                htmlType="submit"
                disabled={isDisabled}
              >
                Login
              </Button>
            </Form>
          </Card>
        </Flex>
      </Layout>
    </>
  );
};

export default Login;
