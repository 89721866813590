import axios from "axios";
import { Config } from "../constants/Config";
import { STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";
import { JSON_DATA } from "../constants/apiEndPoints";

//let storage = new localStoragePrototype();
let baseURL = Config.coreAPI+"/";
/* Logic for accessing CoreAPI */
class CoreAPI {


  constructor() {
    this.apiInstance = axios.create();
    this.setInterceptors();
  }

  setInterceptors() {
    this.apiInstance.interceptors.response.use(
      response => response,
      error => {
        console.log(error,"---", error.response.status)
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          addDeleteGetLocalStorage(STORAGE.TOKEN,"", "delete", "single");
          window.location.href = '/';
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * Sets auth in header for next api calls
   * @param {string} token - string
   */
  setAuthenticationHeader(token) {
    this.apiInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    //axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  /**
   * Return a single error message
   *
   * @param {AxiosError} error - axios error
   */
  errorMessage(error) {
    if (error.response) {
      // Request made and server responded
      return error.response.data.message;
    } else if (error.request) {
      // The request was made but no response was received
      return "Cannot connect to Server. Please check your connection.";
    } else {
      let msg = error?.message || "Authentication fail, please login again";
      return msg;
    }
  }


  /**
   * API Request handler
   * @param {string} path - api endpoint
   * @param {*} body - the body to patch into path
   * @param {*} bodyParams - body parameters of request
   */
  async postRequest(path, body, bodyParams = {}, header = JSON_DATA) {
    let config = {
        headers: header,
        params: bodyParams,
    };

    // If body is FormData, remove the Content-Type header and let the browser set it
    if (body instanceof FormData) {
        delete config.headers['Content-Type'];
    }

    const response = await this.apiInstance.post(baseURL + path, body, config); // Use this.apiInstance
    return response.data;
  }

  /**
   * API Request handler
   * @param {string} path - api endpoint
   * @param {*} bodyParams - body parameters of request
   */
  async getRequest(path, bodyParams = {}) {
    const config = {
      headers: JSON_DATA,
    };

    const response = await this.apiInstance.get((baseURL+path), {
      ...config,
      params: bodyParams,
    });

    return response.data;
  }

  async getExternal(url, bodyParams) {
    const config = {
      headers: JSON_DATA,
    };

    const response = await axios.get(url, {
      ...config,
      params: bodyParams,
    });
    return response.data;
  }

  getToken() {
    let token = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");
    if (typeof token === "string") {
      return token;
    } else {
      return "";
    }
  }
}

export default CoreAPI;
