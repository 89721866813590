import { useState, useEffect } from "react";
import { Card, Form, Image, Modal } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons"; // Import Ant Design play icon
import AthleteService from "../../../service/AthleteService";
import { useParams } from "react-router-dom";
import ReactPlayer from 'react-player';
import { useLoader } from "../../../contexts/LoadingProvider";
import {isValidYouTubeUrl} from '../../../utils/validation';

const AthleteSer = new AthleteService();

const SocialMedia = () => {
  const [socialDetails, setSocialDetails] = useState({});
  const { id } = useParams();
  const { setLoading } = useLoader();

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      const response = await AthleteSer.getImagesVideos(id);
      const data = response?.data;
      setSocialDetails(data);
      setLoading(false);
    }
    fetchProfileData();
  }, [id]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const showModal = (url) => {
    setCurrentVideoUrl(url);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentVideoUrl('');
  };

  return (
    <>
      <Card>
        <div>
          <div className="font-semibold text-base mb-[10px]">
            Video & Images:
          </div>
          <Form
            layout="vertical"
            style={{
              //   maxWidth: 600,
              width: "100%",
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <div className="flex flex-wrap gap-5">
            {socialDetails?.videos?.map((vid, index) => (
              <div key={index} className="w-full max-w-[184px]">
                <div 
                  className="relative w-[184px] h-[184px] flex items-center justify-center rounded-lg overflow-hidden"
                  onClick={() => showModal(vid?.url)}
                >
                  {isValidYouTubeUrl(vid?.url) ? (
                    <ReactPlayer
                      url={vid?.url}
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'cover', pointerEvents: 'none' }}
                      playing={false}
                      light={true}
                      playIcon={<PlayCircleOutlined className="absolute inset-0 m-auto pointer-events-none text-white text-4xl text-center flex items-center justify-center bg-[#00000040]" />}
                    />
                  ) : (
                    <video
                      muted
                      width="200px"
                      height="200px"
                      src={vid?.url}
                      style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                      onClick={(e) => e.preventDefault()}
                    />
                  )}
                  <PlayCircleOutlined className="absolute inset-0 m-auto pointer-events-none text-white text-4xl text-center flex items-center justify-center bg-[#00000040]" />
                </div>
                <div className="font-semibold text-base w-full text-center">
                  {vid?.title}
                </div>
              </div>
            ))}
              {socialDetails?.images?.map((image, index) => (
              <div key={index} className="w-full max-w-[184px]">
                <div className="relative w-[184px] h-[184px] flex items-center justify-center rounded-lg overflow-hidden">
                  <Image
                    width={200}
                    src={image.url}
                  />
                </div>
                <div className="font-semibold text-base  w-full text-center">
                  {image.title}
                </div>
              </div>
              ))}
              
            </div>
          </Form>
          {/* <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-[824px] mt-6">
            <div class="w-full max-w-[400px] p-[26px] flex flex-col border-[#E8E8E8] border items-center justify-between gap-[12px] rounded-lg">
              <PlaySquareOutlined className="text-3xl" />
              <h6 class="text-sm font-semibold">Upload Videos</h6>
              <p class="text-sm text-[#454545] text-center">
                Upload a video with a max file size of 100MB. Acceptable file
                types are .mp4 and .mov.
              </p>
            </div>
            <div class="w-full max-w-[400px] p-[26px] flex flex-col border-[#E8E8E8] border items-center justify-between gap-[12px] rounded-lg">
              <FileImageOutlined className="text-3xl" />
              <h6 class="text-sm font-semibold">Upload Images</h6>
              <p class="text-sm text-[#454545] text-center">
                Upload an image with a max file size of 5MB. Acceptable file
                types are .jpg, .png or .jpeg.
              </p>
            </div>
          </div> */}
        </div>
        <Modal
              open={isModalVisible}
              footer={null}
              onCancel={handleCancel}
              width={800}
              bodyStyle={{ padding: '24px' }}
            >
              <ReactPlayer
                url={currentVideoUrl}
                controls
                playing
                width="100%"
              />
            </Modal>
        {/* <Row>
          <Col span={24}>
            <div className="flex justify-end my-5">
              <Button
                type="primary"
                size={14}
                className="mx-1.5 cursor-pointer flex items-center justify-between"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row> */}
      </Card>
    </>
  );
};
export default SocialMedia;
