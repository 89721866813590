import { useState, useEffect } from "react";
import { Tooltip, Card, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AthleteService from "../../../service/AthleteService";
import { useAlert } from "../../../contexts/AlertProvider";
import moment from 'moment';
import { decodeId } from '../../../utils/encoding';

const AthleteSer = new AthleteService();

const SubscriptionDetails = () => {
  const { id } = useParams();
  const athleteId = decodeId(id);
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [subscriptionListing, setSubscriptionListing] = useState({});
  const [currentSubscription, setCurrentSubscription] = useState({});
  
  useEffect(() => {
    const fetchProfileData = async () => {
      const response = await AthleteSer.getAthleteSubscription(athleteId);
      setSubscriptionListing(response?.data?.rows);
    }
    fetchProfileData();
  }, [id]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await AthleteSer.getCurrentPlan(athleteId);
      setCurrentSubscription(response?.data);
    }
    fetchData();
  }, [id]);
  
  const downloadPdf = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      showAlert("No Invoice to download", "error");
    }
  };
  return (
    <>
      <Card>
        <div>
          <div className="flex justify-between items-center mb-3">
            <div className="font-semibold text-base mb-[10px]">
            Subscription Information
            </div>
          </div>
          <Row>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">Subscribed on</div>
                <div className="text-sm">{moment(currentSubscription?.start_date).format('MM/DD/YYYY')}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">
                  Current plan name
                </div>
                <div className="text-sm">{currentSubscription?.plan_name}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">Amount:</div>
                <div className="text-sm">${currentSubscription?.amount}</div>
              </div>
            </Col>
          </Row>
          <div className="font-semibold mb-2 text-base">Invoice History</div>
          <div className="relative overflow-x-auto">
            <table className="w-full  text-left	 text-sm  text-gray-500">
              <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3 whitespace-nowrap">
                    ID
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Plan
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-3 py-3 text-center">
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center whitespace-nowrap vertical-top"
                  >
                    Download invoice
                  </th>
                </tr>
              </thead>
              <tbody>
              {subscriptionListing?.length > 0 ? (
                subscriptionListing?.map((sub, index) => (
                <tr key={index} className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                  >
                    #{sub?.id}
                  </th>
                  <td className="px-3 py-4 vertical-top">
                    {" "}
                    {moment(sub?.start_date).format('MM/DD/YYYY')} {(sub?.end_date) ? `- ${moment(sub?.end_date).format('MM/DD/YYYY')}` : ''}
                  </td>
                  <td className="px-3 py-4 vertical-top">{sub?.plan_name}</td>
                  <td className="px-3 py-4 vertical-top">${sub?.amount}</td>
                  <td className="px-3 py-4 vertical-top text-center">
                  <span className={`inline-block min-w-[100px]  py-1.5 px-5 rounded-lg capitalize ${
                      sub?.pay_status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                      (sub?.pay_status === 'completed') ? 'text-[#019400] bg-[#01940033]' : 'text-[#DC362E] bg-[#DC362E40]'
                    }`}>{sub?.pay_status}</span>
                  </td>
                  <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                    {(sub?.pdf)?(
                    <Tooltip title="Download">
                      <DownloadOutlined className="mx-1.5 cursor-pointer" onClick={()=>downloadPdf(sub?.pdf)}/>
                    </Tooltip>
                    ):'-'}
                  </td>
                </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center px-3 py-4 text-gray-500">
                      No records found
                    </td>
                  </tr>
                )}
                {/* <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                  >
                    #2374687212
                  </th>
                  <td className="px-3 py-4 vertical-top">
                    {" "}
                    05/25/2024 - 20/25/2024
                  </td>
                  <td className="px-3 py-4 vertical-top">Standard</td>
                  <td className="px-3 py-4 vertical-top">FREE</td>
                  <td className="px-3 py-4 vertical-top text-center">
                    <span className="inline-block min-w-[100px] bg-[#ffb6b6] text-[#ed4242] py-1.5 px-5 rounded-lg">
                      Expired
                    </span>
                  </td>
                  <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                    <Tooltip title="Download">
                      <DownloadOutlined className="mx-1.5 cursor-pointer" />
                    </Tooltip>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                  >
                    #2374687212
                  </th>
                  <td className="px-3 py-4 vertical-top">
                    {" "}
                    05/25/2024 - 20/25/2024
                  </td>
                  <td className="px-3 py-4 vertical-top">Standard</td>
                  <td className="px-3 py-4 vertical-top">FREE</td>
                  <td className="px-3 py-4 vertical-top text-center">
                    <span className="inline-block min-w-[100px] bg-[#ffb6b6] text-[#ed4242] py-1.5 px-5 rounded-lg">
                      Cancelled
                    </span>
                  </td>
                  <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                    <Tooltip title="Download">
                      <DownloadOutlined className="mx-1.5 cursor-pointer" />
                    </Tooltip>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  );
};
export default SubscriptionDetails;
