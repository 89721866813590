// components/PlacesSearch.js
import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Config } from "../constants/Config";

const PlacesSearch = ({initialAddress,onPlaceSelect}) => {
  const [googleAddress, setGoogleAddress] = useState(null);
  const [locationDetails, setLocationDetails] = useState({
    zipcode: '',
    state: '',
    city: '',
    country: '',
    lat: '',
    lng: '',
    address:'',
  });
  
  useEffect(() => {
    if (initialAddress && typeof initialAddress === 'string') {
      setGoogleAddress({
        "label": initialAddress,
        "value": {
            "description": initialAddress,
            "matched_substrings": [
                {
                    "length": 4,
                    "offset": 0
                }
            ],
        }
    }); // Display as label only
    }
  }, [initialAddress]);

  const handlePlaceSelect = async (place) => {
    setGoogleAddress(place);
    const placeId = place?.value?.place_id || '';
    if (placeId) {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${Config?.googleKey}`
        );
        const data = await response.json();
        if (data.status === 'OK') {
            const location = data.results[0];
            const { lat, lng } = location.geometry.location;
            const components = location.address_components;
      
            // Extract specific location details
            const details = {
              zip: components.find(c => c.types.includes("postal_code"))?.long_name || '',
              state: components.find(c => c.types.includes("administrative_area_level_1"))?.long_name || '',
              city: components.find(c => c.types.includes("locality"))?.long_name || '',
              country: components.find(c => c.types.includes("country"))?.long_name || '',
              address: location.formatted_address || '',
              lat,
              lng
            };
            onPlaceSelect(details);
        }
      } catch (error) {
        console.error('Error fetching location details:', error);
      }
    }
  };
  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={Config?.googleKey}
        selectProps={{
          placeholder:'Search for a place',
          isClearable: true,
          onError: (status, clearSuggestions) => {
            clearSuggestions();
          },
          onChange: handlePlaceSelect,
          value: googleAddress,
        }}
        value={googleAddress}
        />
    </div>
  );
};

export default PlacesSearch;
