export const encodeId = (id) => {
    return btoa(id);
  };
  
export const decodeId = (encodedId) => {
    try{
        return atob(encodedId);
    } catch (error){
        return null;
    }
  };