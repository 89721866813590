import {API_ATHLETE_SECTION} from "../constants/apiEndPoints";
import CoreAPI from "./CoreAPI";

class AthleteService extends CoreAPI {

  
  /**
  *
  * @param  {*}
  * @returns
  */
  getCategoryList() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_CATEGORY_LIST);
  }


  /**
  *
  * @param  {*}
  * @returns
  */
  getAthleteList(filter) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_ATHLETE_LIST,filter);
  }

  
  /**
   *
   * @param {*} Id
   * @returns
   */

  getAthleteDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_ATHLETE_DETAILS+id);
  }

  
  /**
   *
   * @param {*} user_id
   * @param {*} status
   * @returns
   */

  updateAthleteStatus(user_id, status) {
    this.setAuthenticationHeader(this.getToken());
    let body={
      user_id,
      status
    }
    return this.postRequest(API_ATHLETE_SECTION.UPDATE_STATUS,body);
  }

  /**
   *
   * @param {*} id
   * @returns
   */

  getAthletePersonalDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_PERSONAL_DETAILS+id);
  }

  /**
   *
   * @param {*} id
   * @returns
   */

  getAthleteAcademicDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_ACADEMIC_DETAILS+id);
  }

  /**
   *
   * @param {*} id
   * @returns
   */

  getAthleteSportsDetails(ids, user_id) {
    this.setAuthenticationHeader(this.getToken());
    let body = {
      user_id: Number(user_id),
      ids: JSON.parse(ids)
    };
    return this.postRequest(API_ATHLETE_SECTION.GET_SPORTS_DETAILS, body);
  }

  /**
   *
   * @param {*} id
   * @returns
   */

  getAthleteUploadFilesDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_UPLOAD_FILES_DETAILS+id);
  }

  getState() {
    return this.getRequest(API_ATHLETE_SECTION.GET_STATE);
  }

  /**
   *
   * @param {*} id
   * @returns
   */

  async getCity(id) {
    return this.getRequest(API_ATHLETE_SECTION.GET_CITY + id);
  }

  
  async updatePersonalDetails(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_ATHLETE_SECTION.UPDATE_PERSONAL_DETAILS, body);
  }

  async updateAcademicDetails(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_ATHLETE_SECTION.UPDATE_ACADEMIC_DETAILS, body);
  }

  async getStudyLevel() {
    return this.getRequest(API_ATHLETE_SECTION.GET_STUDY_LEVEL);
  }

  async updateSportCategoryDetails(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_ATHLETE_SECTION.UPDATE_SPORTS_DETAILS, body);
  }

  async getImagesVideos(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_IMAGE_VIDEO+id);
  }

  async getApplication(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_APPLICATION+id);
  }

  async getAthleteSubscription(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_MY_SUBCRIPTION+id);
  }

  getCurrentPlan(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_CURRENT_PLAN+id);
  }

  getAthleteNilDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_NIL_DETAILS+id);
  }
  
  getAthleteMarketingVideo(filter) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_ATHLETE_MARKETING_VIDEO,filter);
  }

}

export default AthleteService;
