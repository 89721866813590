import { useState, useEffect } from "react";
import { Card, Typography, Tooltip, Button, Select } from "antd";
import {
  DownloadOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import SortingArrow from "../../Common/SortingArrow";
import PaginationComponent from "../../Common/Pagination";
import { useNavigate } from "react-router-dom";
import ViewSetPrice from "../../Modal/ViewSetPrice";
import MotionService from "../../service/MotionService";
import moment from 'moment';

const MotionSer = new MotionService();

const status = [
  {
    value: "",
    label: "All",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "complete",
    label: "Complete",
  },
  {
    value: "error",
    label: "Error",
  },
];

const reportMovement = [];
const MotionReportGeneration = () => {
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState("");
  const [filters, setFilters] = useState({ page: 1, search: '', status: '', type:'', limit:10,sort:'',order:'' });
  const [motionListing, setMotionListing] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState();
  const { Search } = Input;
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const [statusFilter, setStatusFilter] = useState();
  const [isViewSetPriceModalOpen, setIsViewSetPriceModalOpen] = useState(false);
  const showViewSetPriceModal = () => {
    setIsViewSetPriceModalOpen(true);
  };
  const handleViewSetPriceOk = () => {
    setIsViewSetPriceModalOpen(false);
  };
  const handleViewSetPriceCancel = () => {
    setIsViewSetPriceModalOpen(false);
  };
  useEffect(() => {
    const fetchCommanData = async () => {
      try {
        const response = await MotionSer.getMomentTypeList();
        if (response?.data) {
          const categoryArray = response.data.map(cat => ({
            value: cat.types,
            label: cat.types
          }));
          setCategoryListing(categoryArray);
        }
      } catch (error) {
        console.error("Failed to fetch college data:", error);
      }
    };
    fetchCommanData();
  }, []);
  useEffect(() => {
    const filterCollege = async () => {
      const response = await MotionSer.getMotionList(filters);
      setMotionListing(response?.data);
    };
    
    filterCollege();
  }, [filters]);
  const handlePageChange = (page, size) => {
    setFilters({ ...filters, page, limit: size });
  };

  const handleCategoryChange = (value) => {
    if(value?.length !== 0)
     setCategoryFilter(value);
    setFilters({ ...filters, page:1, type: value });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchData(value);
    if (value.length > 2) {
      setFilters({ ...filters, page:1, search: value });
    } else if (value.length === 0) {
      setFilters({ ...filters, page:1, search: '' });
    }
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value);
    setFilters({ ...filters, page:1, status: value });
  };

  const handleSort = (field, key) => {
    setFilters({ ...filters, sort:field, order: key });
  };

  const clearFilter = ()=>{
    setSearchData("");
    setCategoryFilter();
    setStatusFilter();
    setFilters({ ...filters, page:1 ,search: '', status: '', type:'', limit:10, sort:'',order:'' });
  }

  return (
    <>
      <Card>
        <div className="flex justify-between	mb-4 gap-5">
          <div className="">
            <Typography className="text-xl font-semibold" align={"left"}>
              3 Motion Report Generation
            </Typography>
          </div>
          <div className="flex justify-end flex-wrap gap-3">
            <Search
              placeholder="Search by Request ID and athlete name"
              value={searchData}
              style={{
                width: 300,
              }}
              className="cursor-pointer"
              onChange={handleSearchChange}
            />
            <Select
              style={{
                width: 150,
              }}
              placeholder="Status"
              options={status}
              value={statusFilter}
              onChange={handleStatusChange}
              className="cursor-pointer"
            />
            <Select
              style={{
                width: 200,
              }}
              placeholder="Report/movement type"
              options={categoryListing}
              className="cursor-pointer"
              allowClear
              showSearch={false}
              value={categoryFilter}
              onChange={handleCategoryChange}
            />
            <Button type="link" size={14} className="cursor-pointer" onClick={clearFilter}>
            Clear Filter
            </Button>
            <Button
              onClick={showViewSetPriceModal}
              icon={
                <Tooltip title="This price or rate applies to athletes for the report generation service that they are required to pay.">
                  <InfoCircleOutlined />
                </Tooltip>
              }
              iconPosition="end"
              type="primary"
              size={14}
              className="cursor-pointer"
            >
              Set Price
            </Button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-left text-sm  text-gray-500">
            <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  Request ID
                  <SortingArrow onSort={handleSort} field="id"/>
                </th>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  Request date
                  <SortingArrow onSort={handleSort} field="created_at"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Report date <SortingArrow onSort={handleSort} field="updated_at"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Movement type <SortingArrow onSort={handleSort} field="movement_type"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Requested by
                </th>
                <th scope="col" className="px-3 py-3">
                  Amount <SortingArrow onSort={handleSort} field="amount"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Status <SortingArrow onSort={handleSort} field="status"/>
                </th>
                {/* <th scope="col" className="px-3 py-3">
                  Refund
                </th> */}
              </tr>
            </thead>
            <tbody>
            {motionListing?.rows?.length > 0 ? (
              motionListing.rows.map((motion, index) => (
                <tr className="bg-white border-b">
                  <th
                        scope="row"
                        className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                        onClick={() => navigate(`/request-detail/${motion?.id}`)}
                      >
                        #{motion.id}
                      </th>
                    <td className="px-3 py-4 vertical-top">{moment(motion?.created_at).format('MM/DD/YYYY')}</td>
                    <td className="px-3 py-4 vertical-top">
                      {motion?.status === "complete"
                            ? moment(motion.updated_at).format("MM/DD/YYYY")
                            : "-"}
                    </td>
                    <td className="px-3 py-4 vertical-top capitalize">{motion?.movement_type}</td>
                    <td className="px-3 py-4 vertical-top">{motion?.user?.first_name} {motion?.user?.last_name}</td>
                    <td className="px-3 py-4 vertical-top">${motion?.amount}</td>
                    <td className="px-3 py-4 vertical-top">
                      <p className={`text-xs font-medium rounded-lg text-center p-2 capitalize ${
                        motion?.status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                        (motion?.status === 'error') ? 'text-[#DC362E] bg-[#DC362E40]' : 'text-[#019400] bg-[#01940033]'
                      }`}>
                      {motion?.status}
                      </p>
                    </td>
                    {/* <td className="px-3 py-4 vertical-top">
                    {(motion?.refund_status) ? (
                      <p className={`text-xs font-medium rounded-lg text-center p-2 capitalize ${
                        motion?.refund_status === 'refunded' ? 'text-[#019400] bg-[#01940033]' :'text-[#faad14] bg-[#ffd70b40]'
                      }`}>
                      {(motion?.refund_status)?(motion?.refund_status):'-'}
                      </p>
                    ):'-'}
                    </td> */}
                  </tr>
                 ))
                 ) : (
                   <tr>
                     <td colSpan={7} className="text-center px-3 py-4 text-gray-500">
                       No records found
                     </td>
                   </tr>
                 )}
              {/* <tr className="bg-white border-b">
              <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                    onClick={() => navigate("/request-detail")}
                  >
                    #123456
                  </th>
                <td className="px-3 py-4 vertical-top">09/29/2024</td>
                <td className="px-3 py-4 vertical-top">09/30/2024</td>
                <td className="px-3 py-4 vertical-top">type</td>
                <td className="px-3 py-4 vertical-top">Nick Jonas</td>
                <td className="px-3 py-4 vertical-top">$7676</td>
                <td className="px-3 py-4 vertical-top">
                  <p className="text-xs text-[#019400] font-medium bg-[#01940033] rounded-lg text-center p-2">
                  Completed
                  </p>
                </td>
                <td className="px-3 py-4 vertical-top">Completed</td>
              </tr>
              <tr className="bg-white border-b">
              <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                    onClick={() => navigate("/request-detail")}
                  >
                    #123456
                  </th>
                <td className="px-3 py-4 vertical-top">09/29/2024</td>
                <td className="px-3 py-4 vertical-top">09/30/2024</td>
                <td className="px-3 py-4 vertical-top">type</td>
                <td className="px-3 py-4 vertical-top">Nick Jonas</td>
                <td className="px-3 py-4 vertical-top">$7676</td>
                <td className="px-3 py-4 vertical-top">
                  <p className="text-xs text-[#faad14] font-medium bg-[#e8e62047] rounded-lg text-center p-2">
                  Pending
                  </p>
                </td>
                <td className="px-3 py-4 vertical-top">Under processing</td>
              </tr>
              <tr className="bg-white border-b">
              <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                    onClick={() => navigate("/request-detail")}
                  >
                    #123456
                  </th>
                <td className="px-3 py-4 vertical-top">09/29/2024</td>
                <td className="px-3 py-4 vertical-top">09/30/2024</td>
                <td className="px-3 py-4 vertical-top">type</td>
                <td className="px-3 py-4 vertical-top">Nick Jonas</td>
                <td className="px-3 py-4 vertical-top">$7676</td>
                <td className="px-3 py-4 vertical-top">
                  <p className="text-xs text-[#DC362E] font-medium bg-[#DC362E40] rounded-lg text-center p-2">
                  Error
                  </p>
                </td>
                <td className="px-3 py-4 vertical-top">Under processing</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-semibold text-base">
            Total count : <span>{motionListing?.count}</span>
          </div>
          {motionListing?.count > 10 && (
          <PaginationComponent
            currentPage={filters.page}
            total={motionListing?.count}
            pageSize={filters.limit}
            onPageChange={handlePageChange} />
          )}
        </div>
      </Card>
      <ViewSetPrice
        isOpen={isViewSetPriceModalOpen}
        onOk={handleViewSetPriceOk}
        onCancel={handleViewSetPriceCancel}
      />
    </>
  );
};
export default MotionReportGeneration;
