import { useState, useEffect } from "react";
import { Card, Col, Image, Row } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import AthleteService from "../../../service/AthleteService";
import { decodeId } from '../../../utils/encoding';

const AthleteSer = new AthleteService();

const AcademicDetails = () => {
  const { id } = useParams();
  const athleteId = decodeId(id);
  const navigate = useNavigate();
  const [satTotal, setSatTotal] = useState(0);
  const [actTotal, setActTotal] = useState(0);
  const [academicDetails, setAcademicDetails] = useState({});
  useEffect(() => {
    if (!athleteId) {
      navigate('/manage-athlete');
      return;
    }
    const fetchProfileData = async () => {
      const response = await AthleteSer.getAthleteAcademicDetails(athleteId);
      setAcademicDetails(response?.data);
      if(response.data.sat_exam){
        let satmathsval = parseFloat(response.data.sat_reading)+parseFloat(response.data.sat_writing)+parseFloat(response.data.sat_maths);
          setSatTotal(satmathsval);
      }
      if(response.data.act_exam){
        let actmathsval = parseFloat(response.data.act_english)+parseFloat(response.data.act_maths)+parseFloat(response.data.act_reading)+parseFloat(response.data.act_science)+parseFloat(response.data.act_writing);
        setActTotal(parseFloat(actmathsval.toFixed(1)));
      }
    }
    fetchProfileData();
  }, [id]);
  return (
    <>
      <Card>
        <div>
          <div className="flex justify-between items-center mb-3">
            <div className="font-semibold text-base mb-[10px]">
              School Detail:
            </div>
          </div>
          <Row>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">School Name</div>
                <div className="text-sm">
                  {academicDetails?.school_name}
                </div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">Level Of Study</div>
                <div className="text-sm">{academicDetails?.level_study}</div>
              </div>
            </Col>
            {/* <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">
                  Current High School
                </div>
                <div className="text-sm">
                {academicDetails?.current_school}
                </div>
              </div> 
            </Col>*/}
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">State</div>
                <div className="text-sm">{academicDetails?.state}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">City</div>
                <div className="text-sm">{academicDetails?.city}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">Graduation Year</div>
                <div className="text-sm">{academicDetails?.graduate_year}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">GPA</div>
                <div className="text-sm">{academicDetails?.gpa}</div>
              </div>
            </Col>
            {academicDetails?.grade_pic && (
            <Col span={20}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">
                  Uploaded Transcript
                </div>
                <div className="w-full  max-w-[110px] h-[110px] rounded-lg overflow-hidden mt-2">
                  <Image
                    width={110}
                    src={(academicDetails?.grade_pic)}
                  />
                </div>
              </div>
            </Col>
            )}
          </Row>
        </div>
        <div className="font-semibold text-base mb-[10px]">
          SAT Exam Detail:
        </div>
        <Row>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Have you taken SAT exam?
              </div>
              <div className="text-sm">{(academicDetails?.sat_exam)?'Yes':'No'}</div>
            </div>
          </Col>
          {academicDetails?.sat_exam && (
            <>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">SAT Reading</div>
                <div className="text-sm">{academicDetails?.sat_reading}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">SAT Writing</div>
                <div className="text-sm">{academicDetails?.sat_writing}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">SAT Maths</div>
                <div className="text-sm">{academicDetails?.sat_maths}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">SAT Total Score</div>
                <div className="text-sm">{satTotal}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">
                  Uploaded SAT Test Results
                </div>
                <div className="w-full  max-w-[110px] h-[110px] rounded-lg overflow-hidden mt-2">
                  {academicDetails?.sat_result_pic ? (
                  <Image
                    width={110}
                    src={(academicDetails?.sat_result_pic) ? (academicDetails?.sat_result_pic) : "" }
                  />
                  ):"-" }
                </div>
              </div>
            </Col>
            </>
            )}
        </Row>
        <div>
          <div className="font-semibold text-base mb-[10px]">
            ACT Exam Detail:
          </div>
          <Row>
            <Col span={10}>
              <div className="mr-4 my-5">
                <div className="font-semibold text-[17px]">
                  Have you taken ACT exam?
                </div>
                <div className="text-sm">{(academicDetails?.act_exam)?'Yes':'No'}</div>
              </div>
            </Col>
            {academicDetails?.act_exam && (
              <>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">ACT English</div>
                  <div className="text-sm">{academicDetails?.act_english}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">ACT Maths</div>
                  <div className="text-sm">{academicDetails?.act_maths}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">ACT Reading</div>
                  <div className="text-sm">{academicDetails?.act_reading}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">ACT Science</div>
                  <div className="text-sm">{academicDetails?.act_science}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">ACT Writing</div>
                  <div className="text-sm">{academicDetails?.act_writing}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">
                    ACT Total Scores
                  </div>
                  <div className="text-sm">{actTotal}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">ACT result</div>
                  <div className="text-sm">{academicDetails?.act_result_percent}%</div>
                </div>
              </Col>
              <Col span={20}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">Notes</div>
                  <div className="text-sm">
                  {academicDetails?.notes}
                  </div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-[17px]">
                    Uploaded ACT Test results
                  </div>
                  <div className="w-full  max-w-[110px] h-[110px] rounded-lg overflow-hidden mt-2">
                  {academicDetails?.act_result_pic ? (
                    <Image
                      width={110}
                      src={(academicDetails?.act_result_pic) ? (academicDetails?.act_result_pic) : "" }
                    />
                  ):"-" }
                  </div>
                </div>
              </Col>
              </>
            )}
          </Row>
        </div>
      </Card>
    </>
  );
};
export default AcademicDetails;
