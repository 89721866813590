import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Image,
  Upload,
} from "antd";
import { useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import AthleteService from "../../../service/AthleteService";
import { useAlert } from "../../../contexts/AlertProvider";
import { addObject, deleteObject } from "../../../utils/awsS3";
import { Config } from "../../../constants/Config";
import { validateSatNumber, validateActNumber, getFileNameWithRandomNumber } from "../../../utils/validation";
import { useLoader } from "../../../contexts/LoadingProvider";

const AthleteSer = new AthleteService();

const satType=[
  {value:true, label:"Yes"},
  {value:false, label:"No"}
];
const actType=[
  {value:true, label:"Yes"},
  {value:false, label:"No"}
]

const AcademicDetails = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [academicDetails, setAcademicDetails] = useState({});
  const [academicLoaded, setAcademicLoaded] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [studyLevel, setStudyLevel] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [hasTakenAct, setHasTakenAct] = useState(false);
  const [hasTakenSat, setHasTakenSat] = useState(false);
  const graduationYears = Array.from({ length: 2035 - 2025 + 1 }, (_, i) => (2025 + i));
  const [satTotal, setSatTotal] = useState(0);
  const [actTotal, setActTotal] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [actUrl, setActUrl] = useState(null);
  const [satUrl, setSatUrl] = useState(null);

  const [satSingle, setSatSingle] = useState([]);
  const [previewSatImage, setPreviewSatImage] = useState("");
  const [actSingle, setActSingle] = useState([]);
  const [previewActImage, setPreviewActImage] = useState("");
 
  const validateSATScore = (_, value) => {
    if (!value || (value >= 200 && value <= 800)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Score must be between 200 and 800'));
  };

  const validateACTScore = (_, value) => {
    if (!value || (value >= 1 && value <= 32)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Score must be between 1 and 36'));
  };
  const validateACTScore12 = (_, value) => {
    if (!value || (value >= 2 && value <= 12)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Score must be between 200 and 800'));
  };

  const validateACTScorePer = (_, value) => {
    if (!value || (value >= 1 && value <= 100)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Score must be between 1 and 100'));
  };

  const validateGPAScore = (_, value) => {
    if (!value || (value >= 1 && value <= 5)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('GPA must be between 1 and 5'));
  };

  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => {

    if (fileList.length > newFileList.length) {
      const removedFile = fileList.find(file => !newFileList.includes(file));
      if (removedFile) {
        deleteFileFromS3BucketByUrl(removedFile.url || removedFile.thumbUrl, 'transcript');
      }
    }
    setFileList(newFileList);
  };
  
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      const response = await AthleteSer.getAthleteAcademicDetails(id);
      const data = response?.data;
      setHasTakenSat(data?.sat_exam);
      setHasTakenAct(data?.act_exam);
      setAcademicDetails(data);
      if (data?.grade_pic) {
        setPreviewImage(data?.grade_pic);
        setImageUrl(data?.grade_pic);
        setFileList([{
          uid: '-1',
          name: 'grade_pic',
          status: 'done',
          url: data?.grade_pic,
        }]);
      }
      if (data?.sat_result_pic) {
        setPreviewSatImage(data?.sat_result_pic);
        setSatUrl(data?.sat_result_pic);
        setSatSingle([{
          uid: '-2',
          name: 'sat_pic',
          status: 'done',
          url: data?.sat_result_pic
        }])
      }
      if (data?.act_result_pic) {
        setPreviewActImage(data?.act_result_pic);
        setActUrl(data?.act_result_pic);
        setActSingle([{
          uid: '-3',
          name: 'sat_pic',
          status: 'done',
          url: data?.act_result_pic
        }])
      }
      
      if(response.data.sat_exam){
        let satmathsval = parseFloat(response.data.sat_reading)+parseFloat(response.data.sat_writing)+parseFloat(response.data.sat_maths);
        setSatTotal(parseFloat(satmathsval.toFixed(1)));
      }
      if(response.data.act_exam){
        let actmathsval = parseFloat(response.data.act_english)+parseFloat(response.data.act_maths)+parseFloat(response.data.act_reading)+parseFloat(response.data.act_science)+parseFloat(response.data.act_writing);
        setActTotal(parseFloat(actmathsval.toFixed(1)));
      }
      form.setFieldsValue(data);
      setLoading(false);
    }
    fetchProfileData();
    setAcademicLoaded(true);
  }, [id,form]);

  useEffect(() => {
    const fetchData = async () => {
      if (!academicLoaded) {
        return;
      }
      try {
        const response = await AthleteSer.getState();
        setStates(response?.data);
        const studylevel = await AthleteSer.getStudyLevel();
        setStudyLevel(studylevel?.data);
        const state = response.data.find((item) => item.name === academicDetails.state);
        if (state) {
          setSelectedState(state.id);
          form.setFieldsValue({
            state: parseInt(state.id),
          });
        }
      } catch (error) {
        let msg = AthleteSer.errorMessage(error);
        showAlert(msg, "error");
      }
    };
    fetchData();
  }, [academicLoaded]);
  useEffect(() => {
    if (selectedState) {
      const fetchCities = async () => {
        try {
          const response = await AthleteSer.getCity(selectedState);
          setCities(response?.data);
        } catch (error) {
          //showAlert('Failed to fetch cities', 'error');
        }
      };
      fetchCities();
    }
  }, [selectedState]);
  
  const handleSubmit = async (values) => {
    setLoading(true);
    console.log(values,"dd");

    const state = states.find((item) => item.id === values.state);
    try {
      values.state_id = parseInt(selectedState);
      if(state){
        values.state = state.name;
      }
      values.grade_pic =  imageUrl;
      values.act_result_pic = actUrl;
      values.sat_result_pic = satUrl;
      await AthleteSer.updateAcademicDetails({ 
        ...values, user_id: id, 
      });
      showAlert("Academic details updated successfully!", "success");
    } catch (error) {
      showAlert(error.message, "error");
    }
    setLoading(false);
  };

  const handleActExamChange = (value) => {
    setHasTakenAct(value);
  };
  const handleSatExamChange = (value) => {
    setHasTakenSat(value);
  };

  const handleChangeSAT = (e, fieldName) => {
    const value = e.target.value;
    const newval = validateSatNumber(value);
    if (newval || value === '') {
      let sat_maths_val = (parseFloat(form.getFieldValue('sat_reading')) || 0) + (parseFloat(form.getFieldValue('sat_writing')) || 0) + (parseFloat(form.getFieldValue('sat_maths')) || 0);
      setSatTotal(sat_maths_val);
    }
  };
  
  
  const handleChangeACT = (e, fieldName, maxValue=36) => {
    const value = e.target.value;
    const newval = validateActNumber(value, maxValue);
    if (newval || value === '') {
      let act_maths_val = (parseFloat(form.getFieldValue('act_english')) || 0)+(parseFloat(form.getFieldValue('act_maths')) || 0)+(parseFloat(form.getFieldValue('act_reading')) || 0)+(parseFloat(form.getFieldValue('act_science')) || 0)+(parseFloat(form.getFieldValue('act_writing')) || 0);
      setActTotal(parseFloat(act_maths_val.toFixed(1)))
    }
  };

  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
  ];

  const handleFileChange = (event, type) => {
    console.log("type", type);
    const selectedFile = event.target.files[0];
    const maxSizeInMB = 5; // 5MB limit
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxSizeInBytes) {
          uploadFileToS3Bucket(selectedFile, type)
        } else {
          showAlert("File size exceeds 5MB limit.", "error");
        }
      } else {
        showAlert("Invalid file type.", "error");
      }
    }
  };

  const uploadFileToS3Bucket = async (file,type) => {
    if (!file) {
      showAlert("Please select a file first!", "error");
      return;
    }
    try {
      const newFileName = getFileNameWithRandomNumber(file?.name);
      const response = await addObject(newFileName, file);
      const publicUrl = `https://${Config?.s3BucketName}.s3.${Config?.s3Region}.amazonaws.com/${newFileName}`;
      if(type==="transcript"){
        setImageUrl(publicUrl);
        setPreviewImage(publicUrl);
      } else if(type==="sat"){
        setPreviewSatImage(publicUrl);
        setSatUrl(publicUrl);
        setSatSingle([{
          uid: '-1',
          name: 'sat_pic',
          status: 'done',
          url: publicUrl
        }])
      } else if(type==="act"){
        setActUrl(publicUrl);
        setPreviewActImage(publicUrl);
        setActSingle([{
          uid: '-1',
          name: 'act_pic',
          status: 'done',
          url: publicUrl
        }])
      }
    } catch (error) {
      showAlert(error.message, "error");
    }
  };

  const deleteFileFromS3BucketByUrl = async (fileUrl, type) => {
    const key = extractKeyFromUrl(fileUrl);
    try {
      const response = await deleteObject(key);
      if(type==="transcript"){
        setImageUrl('');
      } else if(type==="sat"){
        setSatUrl("");
      } else if(type==="act"){
        setActUrl("");
      }
    } catch (error) {
      showAlert(error.message, "error");
    }
  };


const removeFilesSat = () =>{
  deleteFileFromS3BucketByUrl(satUrl,'sat');
  setSatSingle([]);
  setSatUrl("");
}
const removeFilesAct = () =>{
  deleteFileFromS3BucketByUrl(actUrl,'act');
  setActSingle([]);
  setActUrl("");
}

  const extractKeyFromUrl = (url) => {
    const urlParts = url.split("/");
    return urlParts.slice(3).join("/");
  };

  const handleSatPreview = async () => {
    setPreviewSatImage(satUrl);
    setPreviewOpen(true);
  };

  const handleActPreview = async () => {
    setPreviewActImage(actUrl);
    setPreviewOpen(true);
  };

  return (
    <>
      <Card>
          <div className="font-semibold text-base mb-[10px]">
            School Details:
          </div>
          <Form
            form={form}
            layout="vertical"
            style={{
              //   maxWidth: 600,
              width: "100%",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit}
            onFinishFailed={(errorInfo) => {
              // Handle form submission failure (validation errors)
              showAlert("Please fix the errors in the form.", "error");
            }}
          >
            <Row>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="School Name:"
                    name="school_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your School Name.",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item label="Level Of Study:" name="level_study">
                    <Select size="large"  options={studyLevel.map(study => ({ value: study.name, label: study.name }))} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Current High School"
                    name="current_school"
                  >
                    <Input size="large" />
                  </Form.Item>
                  <p className="text-gray-400">
                      If you are already in high school, input the name of it
                  </p>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your State.",
                      },
                    ]}
                  >
                    <Select size="large" options={states.map(state => ({ value: state.id, label: state.name }))} 
                      onChange={value => setSelectedState(value)}/>
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your City.",
                      },
                    ]}
                  >
                    <Select size="large" options={cities.map(city => ({ value: city.name, label: city.name }))} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <Row>
                  <Col span={12}>
                    <div className="mr-4">
                      <Form.Item label="Graduation Year" name="graduate_year">
                      <Select size="large" options={graduationYears.map(grad => ({ value: grad, label: grad }))} />
                        {/* <DatePicker format="MM/DD/YYYY" className="w-full h-[38px]" picker="year" /> */}
                      </Form.Item>
                      <p className="text-gray-400">Year a player is set to graduate high school </p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="mr-4">
                      <Form.Item
                        label="GPA"
                        name="gpa"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your GPA.",
                          },
                          { validator: validateGPAScore }
                        ]}
                      >
                        <Input size="large" type="number" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
        <div>
          <div className="font-semibold text-base mb-[10px]">Transcript:</div>
            <Row>
              <Col span={6}>
                <div className="mr-4">
                  <Form.Item label="Upload" valuePropName="fileList">
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      maxCount={1}
                      beforeUpload={(file) => {
                        handleFileChange({ target: { files: [file] } },'transcript');
                        return false;
                      }}
                    >
                     {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item>
                  {previewImage && (
                    <Image
                      wrapperStyle={{
                        display: "none",
                      }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  )}
                </div>
              </Col>
            </Row>
        </div>
        <div>
          <div className="font-semibold text-base mb-[10px]">
            SAT Exam Detail
          </div>
            <Row>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Have you taken SAT exam?"
                    name="sat_exam"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your SAT.",
                      },
                    ]}
                  >
                    <Select size="large" options={satType} onChange={handleSatExamChange}/>
                  </Form.Item>
                </div>
              </Col>
              {hasTakenSat === true && (
              <>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="SAT Reading"
                    name="sat_reading"
                    rules={[
                      { required: true, message: 'Please enter your SAT Reading.' },
                      { validator: validateSATScore }
                    ]}
                  >
                    <Input size="large" type="number" onChange={(e) => handleChangeSAT(e, 'sat_reading')}/>
                  </Form.Item>
                  <p className="text-gray-400">Enter the score between 200-800 for subtest</p>
                </div>
              </Col>

              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="SAT Writing"
                    name="sat_writing"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your SAT Writing.",
                      },
                      { validator: validateSATScore }
                    ]}
                  >
                    <Input size="large" type="number" onChange={(e) => handleChangeSAT(e, 'sat_writing')}/>
                  </Form.Item>
                  <p className="text-gray-400">Enter the score between 200-800 for subtest</p>
                </div>
              </Col>

              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="SAT Maths"
                    name="sat_maths"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your SAT Maths.",
                      },
                      { validator: validateSATScore }
                    ]}
                  >
                    <Input size="large" type="number" onChange={(e) => handleChangeSAT(e, 'sat_maths')}/>
                  </Form.Item>
                  <p className="text-gray-400">Enter the score between 200-800 for subtest</p>
                </div>
              </Col>

              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="SAT Total Score"
                  >
                    <Input size="large" value={satTotal} disabled/>
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
            
              <div className="mr-4">
                  <Form.Item label="Upload SAT Test results" valuePropName="fileList">
                    <Upload
                      listType="picture-card"
                      fileList={satSingle}
                      onPreview={handleSatPreview}
                      onRemove={removeFilesSat}
                      maxCount={1}
                      beforeUpload={(file) => {
                        handleFileChange({ target: { files: [file] } },'sat');
                        return false;
                      }}
                    >
                    {satSingle.length >= 1 ? null : uploadButton}
                     {/* {satSingle ? null : uploadButton} */}
                    </Upload>
                  </Form.Item>
                  {previewSatImage && (
                    <Image
                      wrapperStyle={{
                        display: "none",
                      }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewSatImage(""),
                      }}
                      src={previewSatImage}
                    />
                  )}
                </div>
                
              </Col>
              </>
              )}
            </Row>
        </div>
        <div>
          <div className="font-semibold text-base mb-[10px]">
            ACT Exam Detail
          </div>
            <Row>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Have you taken ACT exam?"
                    name="act_exam"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your SAT.",
                      },
                    ]}
                  >
                    <Select size="large" options={actType} onChange={handleActExamChange} />
                  </Form.Item>
                </div>
              </Col>
              {hasTakenAct === true && (
              <>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="ACT English"
                    name="act_english"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your ACT English.",
                      },
                      { validator: validateACTScore }
                    ]}
                  >
                    <Input size="large" type="number" onChange={(e) => handleChangeACT(e, 'act_english')}/>
                  </Form.Item>
                  <p className="text-gray-400">Enter the score between 1-36</p>
                </div>
              </Col>

              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="ACT Maths"
                    name="act_maths"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your ACT Maths.",
                      },
                      { validator: validateACTScore }
                    ]}
                  >
                    <Input size="large" type="number" onChange={(e) => handleChangeACT(e, 'act_maths')}/>
                  </Form.Item>
                  <p className="text-gray-400">Enter the score between 1-36</p>
                </div>
              </Col>

              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="ACT Reading"
                    name="act_reading"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your ACT Reading.",
                      },
                      { validator: validateACTScore }
                    ]}
                  >
                    <Input size="large" type="number" onChange={(e) => handleChangeACT(e, 'act_reading')}/>
                  </Form.Item>
                  <p className="text-gray-400">Enter the score between 1-36</p>
                </div>
              </Col>

              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="ACT Science"
                    name="act_science"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your ACT Science.",
                      },
                      { validator: validateACTScore }
                    ]}
                  >
                    <Input size="large" type="number" onChange={(e) => handleChangeACT(e, 'act_science')}/>
                  </Form.Item>
                  <p className="text-gray-400">Enter the score between 1-36</p>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="ACT Writing"
                    name="act_writing"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your ACT Writing.",
                      },
                      { validator: validateACTScore12 }
                    ]}
                  >
                    <Input size="large" type="number" onChange={(e) => handleChangeACT(e, 'act_writing')}/>
                  </Form.Item>
                  <p className="text-gray-400">Enter the score between 1-36</p>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item label="ACT Total Scores">
                    <Input size="large" value={actTotal} disabled/>
                  </Form.Item>
                </div>
              </Col>
              
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item label="ACT Result" name="act_result_percent"
                  rules={[
                    {
                      required: true,
                      message: "Please enter ACT result.",
                    },
                    { validator: validateACTScorePer }
                  ]}>
                    <Input size="large" type="number" suffix="%" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={20}>
                <div className="mr-4">
                  <Form.Item
                    label="Notes"
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your Notes.",
                      },
                    ]}
                  >
                    <Input.TextArea showCount maxLength={200} size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
              <div className="mr-4">
                  <Form.Item label="Upload ACT Test results" valuePropName="fileList">
                    <Upload
                      listType="picture-card"
                      fileList={actSingle}
                      onPreview={handleActPreview}
                      onRemove={removeFilesAct}
                      maxCount={1}
                      beforeUpload={(file) => {
                        handleFileChange({ target: { files: [file] } },'act');
                        return false;
                      }}
                    >
                     {actSingle.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item>
                  {previewActImage && (
                    <Image
                      wrapperStyle={{
                        display: "none",
                      }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewActImage(""),
                      }}
                      src={previewActImage}
                    />
                  )}
                </div>
                
              </Col>
              </>
              )}
            </Row>
        </div>
        <Row>
          <Col span={20}>
            <div className="flex justify-end my-5 mr-4">
              <Button
                type="primary"
                size={14}
                className="mx-1.5 cursor-pointer flex items-center justify-between"
                htmlType="submit"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
        </Form>
      </Card>
    </>
  );
};
export default AcademicDetails;
