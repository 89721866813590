import {API_COACH_SECTION} from "../constants/apiEndPoints";
import CoreAPI from "./CoreAPI";

class CoachService extends CoreAPI {

  
  /**
  *
  * @param  {*}
  * @returns
  */
  getCollegeList() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_COACH_SECTION.GET_COLLEGE_LIST);
  }


  /**
  *
  * @param  {*}
  * @returns
  */
  getCoachList(filter) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_COACH_SECTION.GET_COACH_LIST,filter);
  }

  
  /**
   *
   * @param {*} Id
   * @returns
   */

  getCoachDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_COACH_SECTION.GET_COACH_DETAILS+id);
  }

  /**
   *
   * @param {*} body
   * @returns
   */

  addCoach(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_COACH_SECTION.ADD_COACH,body);
  }


  /**
   *
   * @param {*} body
   * @returns
   */

  updateCoach(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_COACH_SECTION.UPDATE_COACH,body);
  }

  /**
   *
   * @param {*} Id
   * @returns
   */

  deleteCoach(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_COACH_SECTION.DELETE_COACH+id);
  }

  /**
   *
   * @param {*} Id
   * @param {*} status
   * @returns
   */

  updateCoachStatus(id=0, status, code="") {
    //this.setAuthenticationHeader(this.getToken());
    let body={
      id,
      status,
      code
    }
    return this.postRequest(API_COACH_SECTION.UPDATE_STATUS,body);
  }

  /**
   *
   * @param {*} Id
   * @returns
   */

  resendCoachMail(coach_id) {
    this.setAuthenticationHeader(this.getToken());
    let body={
      coach_id
    }
    return this.postRequest(API_COACH_SECTION.RESEND_COACH_MAIL, body);
  }

}

export default CoachService;
