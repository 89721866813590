import { useEffect } from "react";
import { Layout, Card, Flex } from "antd";
import { useParams } from "react-router-dom";
import CoachService from "../../service/CoachService";

const CoachSer = new CoachService();
const AcceptingRejecting = () => {
  const { status, code } = useParams();
  useEffect(() => {
    const updateStatus = async () => {
      if (status && code) {
        await CoachSer.updateCoachStatus({ id: 0, status, code });
      }
    };
    updateStatus();
  }, [status, code]);
  return (
    <>
      <Layout>
        <Flex
          gap="middle"
          align="center"
          justify="center"
          vertical
          style={{ minHeight: "100vh" }}
        >
          <Card
            className="relative"
            style={{
              width: 500,
              height: 400,
              textAlign:"center",
              alignContent:"center",
            }}
          >
          {status === "active" && (
              <>
                <img
                  width={70}
                  src="/images/done.svg"
                  alt=""
                  className="mx-auto mb-5"
                />
                <h6 className="text-lg">
                  You have successfully <b>“accepted”</b> the invitation. If you
                  change your mind, please contact the admin for further
                  assistance.
                </h6>
              </>
            )}
            {status === "inactive" && (
              <>
                <img
                  width={70}
                  src="/images/cancel.svg"
                  alt=""
                  className="mx-auto mb-5"
                />
                <h6 className="text-lg">
                  You have successfully <b>“rejected”</b> the invitation. If you
                  change your mind, please contact the admin for further
                  assistance.
                </h6>
              </>
            )}
          </Card>
        </Flex>
      </Layout>
    </>
  );
};

export default AcceptingRejecting;
