const API_ENDPOINT = {
    SIGNIN: "v1/admin/signin",
    CHANGE_PASSWORD: "v1/admin/update-password",
    FORGOT_PASSWORD: "auth/v1/users/forgot-password",
    RESET_PASSWORD: "auth/v1/users/reset-password",
    GET_PROFILE: "v1/admin/get-profile",
    UPDATE_PROFILE: "v1/admin/updateProfile",
    REFESHTOKEN: "v1/users/refresh-token",
};

export const API_COACH_SECTION = {
    GET_COLLEGE_LIST: "v1/admin/college-list",
    GET_COACH_DETAILS: "v1/admin/coach-detail/",
    ADD_COACH: "v1/admin/add-coach",
    UPDATE_COACH: "v1/admin/update-coach",
    GET_COACH_LIST: "v1/admin/get-coach",
    DELETE_COACH: "v1/admin/delete-coach/",
    UPDATE_STATUS: "v1/admin/update-status",
    RESEND_COACH_MAIL: "v1/admin/coach-resendverification"
}

export const API_ATHLETE_SECTION = {
    GET_CATEGORY_LIST: "v1/users/sports-category",
    GET_ATHLETE_LIST: "v1/admin/get-athlet",
    GET_ATHLETE_DETAILS: "v1/admin/get-athlet/",
    UPDATE_STATUS: "v1/admin/athlet-status",
    GET_PERSONAL_DETAILS: "v1/admin/get-athlet/",
    GET_ACADEMIC_DETAILS: "v1/admin/get-academy/",
    GET_SPORTS_DETAILS: "v1/admin/sports-category-field",
    GET_UPLOAD_FILES_DETAILS: "v1/admin/get-files/",
    GET_STATE: "v1/users/get-state/1",
    GET_CITY: "v1/users/get-city/",
    UPDATE_PERSONAL_DETAILS: "/v1/admin/athlet-profile-update",
    UPDATE_ACADEMIC_DETAILS: "/v1/admin/academic-update",
    GET_STUDY_LEVEL: "v1/users/get-studylevel",
    UPDATE_SPORTS_DETAILS: "v1/admin/post-sport",
    GET_IMAGE_VIDEO: "v1/admin/get-files/",
    GET_APPLICATION: "v1/admin/get-application/",
    GET_MY_SUBCRIPTION: "v1/admin/my-subscription/",
    GET_CURRENT_PLAN: "v1/subscription/list/",
    GET_NIL_DETAILS: "v1/admin/get-nil/",
    GET_ATHLETE_MARKETING_VIDEO: "v1/admin/athlete-video-list"
}

export const API_COLLEGE_SECTION = {
    GET_DIVISION_LIST: "v1/users/get-division",
    ADD_COLLEGE: "v1/admin/add-college",
    UPDATE_COLLEGE: "v1/admin/update-college",
    GET_COLLEGE_LIST: "v1/admin/colleges",
    GET_COLLEGE_DETAILS: "v1/admin/detail/",
    UPDATE_COLLEGE_STATUS: "v1/admin/update-college-status",
    GET_COLLEGE_COACH : "v1/admin/coachby-college",
}

export const API_MOTION_SECTION = {
    GET_PRICE_DETAIL: "v1/admin/get-report-price/1",
    UPDATE_PRICE_DETAIL: "v1/admin/set-report-price",
    GET_MOMENT_TYPE_LIST: "v1/admin/subcategory-type",
    GET_MOTION_LIST: "v1/admin/get-report",
    GET_MOTION_DETAILS: "v1/admin/motion-report-detail/",
    GET_SUBCRIPTION_LIST: "v1/admin/subscription-list",
    GET_EXPORT_LIST: "v1/admin/download-report",
    GET_ATHLETE_LIST: "v1/admin/get-athlete-list",
    GET_PDF_INVOICE: "v1/admin/download-report-pdf/",
    GET_MOTION_EXPORT: "v1/admin/download-report",
    GET_EXPORT_SUBSCRIPTION: "v1/admin/download-csv/",
}

export const API_PLAN_SECTION = {
    GET_PLAN_LIST: "v1/admin/get-plan-list",
    GET_PLAN_DETAILS: "v1/admin/get-plan-detail/",
    UPDATE_PLAN_STATUS: "v1/admin/update-plan-status",
    UPDATE_PLAN_DETAILS: "v1/admin/update-plan-detail",
    GET_PLAN_SUBCCRIPTION_LIST: "v1/admin/subscription-byplan/"
}

export const JSON_DATA = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key":process.env.REACT_APP_API_KEY,
};

export const FORM_DATA = {
    "Content-Type": "multipart/form-data",
};

export default API_ENDPOINT;