import API_ENDPOINT from "../constants/apiEndPoints";
import CoreAPI from "./CoreAPI";

class AuthService extends CoreAPI {

  /**
   *
   * @param {*} email
   * @param  {*} password
   * @returns
   */

  signIn(email, password) {
    let body = {
      email,
      password,
    };
    return this.postRequest(API_ENDPOINT.SIGNIN, body);
  }

  /**
   *
   * @param {*} old_password
   * @param  {*} new_password
   * @param {*} confirm_password
   * @returns
   */

  changePassword(old_password, new_password, confirm_password) {
    this.setAuthenticationHeader(this.getToken());
    let body = {
      old_password,
      new_password,
      confirm_password,
    };
    return this.postRequest(API_ENDPOINT.CHANGE_PASSWORD, body);
  }

  async forgotPassword(email,type="admin") {
    let body = {
      email,
      type,
    };
    return this.postRequest(API_ENDPOINT.FORGOT_PASSWORD, body);
  }

  /**
   *
   * @param {*} code
   * @param {*} new_password
   * @param {*} confirm_password
   * @returns
   */

  async resetPassword(code, new_password, confirm_password) {
    let body = {
      code,
      new_password,
      confirm_password,
    };
    return this.postRequest(API_ENDPOINT.RESET_PASSWORD, body);
  }

  /**
   *
   * @param {*} Id
   * @returns
   */

  async getProfile() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ENDPOINT.GET_PROFILE);
  }

  async updateProfile(data) {
    this.setAuthenticationHeader(this.getToken());
    let body = {
      first_name:data.first_name,
      last_name:data.last_name,
      profile_image:data.profile_image,
    };
    return this.postRequest(API_ENDPOINT.UPDATE_PROFILE, body);
  }

  async refreshToken(email) {
    let body = {
      email
    };
    return this.postRequest(API_ENDPOINT.REFESHTOKEN, body);
  }

}

export default AuthService;
