import { useState, useEffect } from "react";
import { Card, Form, Image, Modal } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons"; // Import Ant Design play icon
import { useParams } from "react-router-dom";
import AthleteService from "../../../service/AthleteService";
import ReactPlayer from 'react-player';
import {isValidYouTubeUrl} from '../../../utils/validation';
import { decodeId } from '../../../utils/encoding';

const AthleteSer = new AthleteService();

const SocialMedia = () => {
  const { id } = useParams();
  const [uploadFilesData, setUploadFilesData] = useState({});

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const showModal = (url) => {
    setCurrentVideoUrl(url);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentVideoUrl('');
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      const athleteId = decodeId(id);
      const response = await AthleteSer.getAthleteUploadFilesDetails(athleteId);
      setUploadFilesData(response?.data);
    }
    fetchProfileData();
  }, [id]);
  return (
    <>
      <Card>
        <div>
          <div className="flex justify-between items-center mb-3">
            <div className="font-semibold text-base mb-[10px]">
              Video & Images:
            </div>
          </div>
          <Form
            layout="vertical"
            style={{
              //   maxWidth: 600,
              width: "100%",
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <div className="flex flex-wrap gap-5">
            {uploadFilesData?.images?.map((img, index) => (
             <div key={index} className="w-full max-w-[184px]">
                <div className="relative w-[184px] h-[184px] flex items-center justify-center rounded-lg overflow-hidden">
                  <Image
                    width={200}
                    height={200}
                    src={img.url}
                  
                  />
                </div>
                <div className="font-semibold text-base  w-full text-center">
                  {img.title}
                </div>
              </div>
            ))}
            {uploadFilesData?.videos?.map((vid, index) => (
                  <div key={index} className="w-full max-w-[184px]">
                    <div 
                      className="relative w-[184px] h-[184px] flex items-center justify-center rounded-lg overflow-hidden"
                      onClick={() => showModal(vid?.url)}
                    >
                      {isValidYouTubeUrl(vid?.url) ? (
                        <ReactPlayer
                          url={vid?.url}
                          width="100%"
                          height="100%"
                          style={{ objectFit: 'cover', pointerEvents: 'none' }}
                          playing={false}
                          light={true}
                          playIcon={<PlayCircleOutlined className="absolute inset-0 m-auto pointer-events-none text-white text-4xl text-center flex items-center justify-center bg-[#00000040]" />}
                        />
                      ) : (
                        <video
                          muted
                          width="200px"
                          height="200px"
                          src={vid?.url}
                          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                          onClick={(e) => e.preventDefault()}
                        />
                      )}
                      <PlayCircleOutlined className="absolute inset-0 m-auto pointer-events-none text-white text-4xl text-center flex items-center justify-center bg-[#00000040]" />
                    </div>
                    <div className="font-semibold text-base w-full text-center">
                      {vid?.title}
                    </div>
                  </div>
                ))}
              
            </div>
          </Form>

          <Modal
              open={isModalVisible}
              footer={null}
              onCancel={handleCancel}
              width={800}
              bodyStyle={{ padding: '24px' }}
            >
              <ReactPlayer
                url={currentVideoUrl}
                controls
                playing
                width="100%"
              />
            </Modal>
        </div>
      </Card>
    </>
  );
};
export default SocialMedia;
