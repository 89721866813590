import {API_ATHLETE_SECTION, API_COLLEGE_SECTION} from "../constants/apiEndPoints";
import CoreAPI from "./CoreAPI";

class CollegeService extends CoreAPI {

  
  /**
  *
  * @param  {*}
  * @returns
  */
  getCategoryList() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_ATHLETE_SECTION.GET_CATEGORY_LIST);
  }

  /**
  *
  * @param  {*}
  * @returns
  */
  getDivisionList() {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_COLLEGE_SECTION.GET_DIVISION_LIST);
  }

  /**
   *
   * @param {*} body
   * @returns
   */

  addCollege(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_COLLEGE_SECTION.ADD_COLLEGE,body);
  }

  /**
   *
   * @param {*} body
   * @returns
   */

  updateCollege(body) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_COLLEGE_SECTION.UPDATE_COLLEGE,body);
  }

  /**
  *
  * @param  {*}
  * @returns
  */
   getCollegeList(filter) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_COLLEGE_SECTION.GET_COLLEGE_LIST,filter);
  }

  /**
   *
   * @param {*} Id
   * @returns
   */

  getCollegeDetails(id) {
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_COLLEGE_SECTION.GET_COLLEGE_DETAILS+id);
  }

  updateCollegeStatus(id,status) {
    this.setAuthenticationHeader(this.getToken());
    return this.postRequest(API_COLLEGE_SECTION.UPDATE_COLLEGE_STATUS,{id:id,status:status});
  }

  getCollegeCoach(filter){
    this.setAuthenticationHeader(this.getToken());
    return this.getRequest(API_COLLEGE_SECTION.GET_COLLEGE_COACH,filter);
  }
}

export default CollegeService;
