import AuthService from "./AuthService";
import { STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";

const AuthSer = new AuthService();

export const refreshToken = async (email) => {
    try {
      let result = await AuthSer.refreshToken(email);
      addDeleteGetLocalStorage(STORAGE.TOKEN, result?.data,"add","single");
      return result?.data;
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
};