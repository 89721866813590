import { useState, useEffect } from "react";
import { Tooltip, Card, Typography, Select, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Input } from "antd";
import SortingArrow from "../../Common/SortingArrow";
import PaginationComponent from "../../Common/Pagination";
import AthleteService from "../../service/AthleteService";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";
const AthleteSer = new AthleteService();
const VideoPrivacy = [
  {
    value: "public",
    label: "Public",
  },
  {
    value: "private",
    label: "Private",
  },
];
const MarketingVideos = () => {
  const { Search } = Input;
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [searchData, setSearchData] = useState("");
  const [searchDataVideo, setSearchDataVideo] = useState("");
  const [settingFilter, setSettingFilter] = useState();
  const [athleteVideoListing, setAthleteVideoListing] = useState([]);
  const [filters, setFilters] = useState({ page: 1, search_athlete: '', search_video:'',type: '', limit:10,sort:'',order:'' });

  useEffect(() => {
    const filterAthletes = async () => {
      setLoading(true);
      const response = await AthleteSer.getAthleteMarketingVideo(filters);
      setAthleteVideoListing(response?.data);
      setLoading(false);
    };
    
    filterAthletes();
  }, [filters]);

  const handleSettingChange = (value) => {
    setSettingFilter(value);
    setFilters({ ...filters, page:1, type: value });
  };

  const clearFilter = ()=>{
    setSearchData("");
    setSearchDataVideo("");
    setSettingFilter();
    setFilters({ ...filters, page: 1, search_athlete: '', search_video:'', type:'', limit:10, sort:'',order:'' });
  }
  const handlePageChange = (page, size) => {
    setFilters({ ...filters, page, limit: size });
  };

  const handleDownloadVideo = (url) => {
    window.open(url, "_blank");
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchData(value);
    if (value.length > 2) {
      setFilters({ ...filters, page:1, search_athlete: value });
    } else if (value.length === 0) {
      setFilters({ ...filters, page:1, search_athlete: '' });
    }
  };

  const handleSearchVideoChange = (e) => {
    const value = e.target.value;
    setSearchDataVideo(value);
    if (value.length > 2) {
      setFilters({ ...filters, page:1, search_video: value });
    } else if (value.length === 0) {
      setFilters({ ...filters, page:1, search_video: '' });
    }
  };

  const handleSort = (field, key) => {
    setFilters({ ...filters, page:1, sort:field, order: key });
  };

  return (
    <>
      <Card>
        <div className="flex justify-between mb-4 gap-5">
          <Typography className="text-xl font-semibold" align={"left"}>
            Marketing Videos
          </Typography>
          <div className="relative">
            <Search
              placeholder="Search by athlete name"
              value={searchData}
              style={{
                width: 200,
              }}
              className="mx-1.5 cursor-pointer"
              onChange={handleSearchChange}
            />
            <Search
              placeholder="Search by video title"
              value={searchDataVideo}
              style={{
                width: 150,
              }}
              className="mx-1.5 cursor-pointer"
              onChange={handleSearchVideoChange}
            />
            <Select
              style={{
                width: 200,
              }}
              placeholder="Filter by Video Privacy"
              maxTagCount="responsive"
              options={VideoPrivacy}
              onChange={handleSettingChange}
              //allowClear
              value={settingFilter}
              showSearch={false}
              filterOption={false}
              className="mx-1.5 cursor-pointer"
            />
            <Button type="link" size={14} className="cursor-pointer" onClick={clearFilter}>
              Clear Filter
            </Button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full  text-left	 text-sm  text-gray-500">
            <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  Athlete name 
                </th>
                <th scope="col" className="px-3 py-3">
                  Video title {" "}
                  <SortingArrow onSort={handleSort} field="title" />
                </th>
                <th scope="col" className="px-3 py-3">
                  Video URL{" "}
                  <SortingArrow onSort={handleSort} field="url" />
                </th>
                <th scope="col" className="px-3 py-3">
                  Video Privacy{" "}
                  <SortingArrow onSort={handleSort} field="setting" />
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap vertical-top"
                >
                  Download video
                </th>
              </tr>
            </thead>
            <tbody>
            {athleteVideoListing?.rows?.length > 0 ? (
              athleteVideoListing.rows.map((athlete, index) => (
              <tr className="bg-white border-b">
                <th
                  scope="row"
                  className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                >
                  {athlete?.user?.first_name} {athlete?.user?.last_name}
                </th>
                <td className="px-3 py-4 vertical-top capitalize">{athlete?.title}</td>
                <td className="px-3 py-4 vertical-top">
                  {athlete?.url}
                </td>
                <td className="px-3 py-4 vertical-top capitalize">{athlete?.setting}</td>
                <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                  <Tooltip title="Download">
                    <DownloadOutlined className="mx-1.5 cursor-pointer" onClick={() =>handleDownloadVideo(athlete?.url)} />
                  </Tooltip>
                </td>
              </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center px-3 py-4 text-gray-500">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-semibold text-base">
            Total count : <span>{athleteVideoListing?.count}</span>
          </div>
          {athleteVideoListing?.count > 10 && (
            <PaginationComponent
              currentPage={filters.page}
              total={athleteVideoListing?.count}
              pageSize={filters.limit}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </Card>
    </>
  );
};
export default MarketingVideos;
