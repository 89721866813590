import { useState, useEffect } from "react";
import { Collapse, Divider, Image } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import AthleteService from "../../../service/AthleteService";
import { decodeId } from '../../../utils/encoding';

const AthleteSer = new AthleteService();

const Nil = () => {
  const { Panel } = Collapse;
  const { id } = useParams();
  const athleteId = decodeId(id);
  const navigate = useNavigate();
  const [athleteNilData, setAthleteNilData] = useState({});
  useEffect(() => {
    if (!athleteId) {
      navigate('/manage-athlete');
      return;
    }
    const fetchProfileData = async () => {
      const response = await AthleteSer.getAthleteNilDetails(athleteId);
      setAthleteNilData(response?.data);
    }
    fetchProfileData();
  }, [id]);

  const handleUrl = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url;
    }
    window.open(url, '_blank');
  };

  const commonFunctionforData = (field) => {
    const selectedValues = athleteNilData?.[field]?.selectedValues || [];
    const otherValue = athleteNilData?.[field]?.otherValue || '-';
  
    const nonOtherValues = selectedValues.filter(value => value !== 'Other');
    const otherValues = selectedValues.filter(value => value === 'Other').map(() => otherValue);
  
    const displayValues = [...nonOtherValues, ...otherValues].join(', ');
  
    return displayValues;
  };
  return (
    <>
      <Collapse accordion>
        <Panel
          header={
            <div className="flex items-center gap-3">
              <Image
                src="/images/nil1.png"
                alt="nil1"
                shadow="none"
                radius="none"
                width={"48px"}
                height={"48px"}
                preview={false}
              />
              <div className="relative">
                <h1 className="text-base font-semibold">
                  Social Media Engagement Metrics
                </h1>
                <p className="text-sm">
                  Enhance your existing social media links to attract potential
                  brand partnerships.
                </p>
              </div>
            </div>
          }
          key="1"
        >
          <div className="bg-transparent sm:bg-[#fff] rounded-lg p-0 sm:p-6 pb-10 flex flex-col ">
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/Link.svg"
                  alt="link"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Social Media Accounts
                </h6>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-3">
              <>
              {(athleteNilData?.social_media)?
                  athleteNilData?.social_media?.map((social, index) => (
               <>
                <div className="relative">
                  <h6 className="text-sm font-normal">
                    <span className="capitalize">Instagram</span> :{" "}
                    <span className="underline font-medium">
                      {social?.link}
                    </span>
                  </h6>
                </div>
                
                <div className="relative">
                  <h6 className="text-sm font-normal">
                    Followers : <span className="font-medium">{social?.follower}</span>
                  </h6>
                </div>
                </>
                )):'-'}
              </>
            </div>
            <Divider className="my-7" />
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/thumb_up.svg"
                  alt="thumb"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Post Engagement Rates
                </h6>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="relative">
                <h6 className="text-sm font-normal">
                  Likes : <span className="font-medium">{(athleteNilData?.engage_post?.likes)?(athleteNilData?.engage_post?.likes):'-'}</span>
                </h6>
              </div>
              <div className="relative">
                <h6 className="text-sm font-normal">
                  Comments : <span className="font-medium">{(athleteNilData?.engage_post?.comments)?(athleteNilData?.engage_post?.comments):'-'}</span>
                </h6>
              </div>
              <div className="relative">
                <h6 className="text-sm font-normal">
                  Shares : <span className="font-medium">{(athleteNilData?.engage_post?.shares)?(athleteNilData?.engage_post?.shares):'-'}</span>
                </h6>
              </div>
            </div>
          </div>
        </Panel>
        <Panel
          header={
            <div className="flex items-center gap-3">
              <Image
                src="/images/nil2.png"
                alt="nil2"
                shadow="none"
                radius="none"
                width={"48px"}
                height={"48px"}
                preview={false}
              />
              <div className="relative">
                <h1 className="text-base font-semibold">
                  Content Creation Capabilities
                </h1>
                <p className="text-sm">
                  Showcase your ability to creater engaging content.
                </p>
              </div>
            </div>
          }
          key="2"
        >
          <div className="bg-transparent sm:bg-[#fff] rounded-lg p-0 sm:p-6 pb-10 flex flex-col ">
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/List.svg"
                  alt="List"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">Content Creation</h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">
            {commonFunctionforData('content_type')}
            </h6>
            <Divider className="my-7" />
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/calendar.svg"
                  alt="calendar"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Social Media Posting Frequency
                </h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">{(athleteNilData?.post_frequency)?(athleteNilData?.post_frequency):'-'}</h6>
            <Divider className="my-7" />
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/calendar.svg"
                  alt="calendar"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">Best Work</h6>
              </div>
            </div>
            <div className="py-5 w-full max-w-[680px] flex flex-col justify-center items-center">
              <div className="w-full flex flex-col gap-6">
              {(athleteNilData?.content_link?.link?.length > 0 || athleteNilData?.content_link?.image?.length > 0) ? (
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5">
                  {athleteNilData?.content_link?.link?.map((links, index) => ( 
                  <div key={index}  className="flex flex-col gap-2 items-center">
                    <div className="relative">
                      <Image
                        src="/images/Link.svg"
                        alt="Sportplan"
                        shadow="none"
                        radius="8px"
                        width={"140px"}
                        height={"140px"}
                        className="rounded-lg cursor-pointer"
                        preview={false}
                        onClick={()=>handleUrl(links?.url)}
                      />
                      <div className="text-sm leading-4 font-semibold text-[#1D2137]">
                        {links?.title} 
                      </div>
                    </div>
                    <div className="text-sm leading-4 font-semibold text-[#1D2137]">
                      The Dream
                    </div>
                  </div>
                  ))}
                  {athleteNilData.content_link?.image?.map((images, index) => (  
                    <div key={index} className="flex flex-col gap-2 items-center">
                        <div className="relative w-full">
                          <Image
                            src={images?.url}
                            alt="Sportplan"
                            shadow="none"
                            radius="8px"
                            width={"140px"}
                            height={"140px"}
                            className="rounded-lg"
                          />
                        </div>
                        <div className="text-sm leading-4 font-semibold text-[#1D2137]">
                          {images?.title} 
                        </div>
                      </div>
                    ))}
                </div>
                 ):'-' }
              </div>
            </div>
          </div>
        </Panel>
        <Panel
          header={
            <div className="flex items-center gap-3">
              <Image
                src="/images/nil3.png"
                alt="nil3"
                shadow="none"
                radius="none"
                width={"48px"}
                height={"48px"}
                preview={false}
              />
              <div className="relative">
                <h1 className="text-base font-semibold">Brand Preferences</h1>
                <p className="text-sm">
                  Help brands understand your interests and previous
                  experiences.
                </p>
              </div>
            </div>
          }
          key="3"
        >
          <div className="bg-transparent sm:bg-[#fff] rounded-lg p-0 sm:p-6 pb-10 flex flex-col ">
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/List.svg"
                  alt="List"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Industries Inspired
                </h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">{commonFunctionforData('brand_industry')}</h6>
            <Divider className="my-7" />
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/brand_awareness.svg"
                  alt="brand_awareness"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Brands They Admire
                </h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">{(athleteNilData?.prefered_brand)?(athleteNilData?.prefered_brand).join(', '):'-'}</h6>
            <Divider className="my-7" />
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/handshake.svg"
                  alt="handshake"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">Partnered With</h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">{(athleteNilData?.previous_partnership)?(athleteNilData?.previous_partnership).join(', '):'-'}</h6>
          </div>
        </Panel>
        <Panel
          header={
            <div className="flex items-center gap-3">
              <Image
                src="/images/nil4.png"
                alt="nil4"
                shadow="none"
                radius="none"
                width={"48px"}
                height={"48px"}
                preview={false}
              />
              <div className="relative">
                <h1 className="text-base font-semibold">
                  NIL Opportunities Preferences
                </h1>
                <p className="text-sm">
                  Specify the types of NIL deals you’re interested in.
                </p>
              </div>
            </div>
          }
          key="4"
        >
          <div className="bg-transparent sm:bg-[#fff] rounded-lg p-0 sm:p-6 pb-10 flex flex-col ">
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/List.svg"
                  alt="List"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">Deals Looking For</h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">
            {commonFunctionforData('deal_sought')}
            </h6>
            <Divider className="my-7" />
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/account_balance_wallet.svg"
                  alt="account_balance_wallet"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Preferred Compensation
                </h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">{commonFunctionforData('compensation_preference')}</h6>
            <Divider className="my-7" />
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/celebration.svg"
                  alt="celebration"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Open to Events or Appearances
                </h6>
              </div>
            </div>
            <h6 className="text-sm font-normal capitalize">{(athleteNilData?.event_availability)?(athleteNilData?.event_availability):'-'}</h6>
          </div>
        </Panel>
        <Panel
          header={
            <div className="flex items-center gap-3">
              <Image
                src="/images/nil5.png"
                alt="nil5"
                shadow="none"
                radius="none"
                width={"48px"}
                height={"48px"}
                preview={false}
              />
              <div className="relative">
                <h1 className="text-base font-semibold">
                  Personal Interests and Hobbies
                </h1>
                <p className="text-sm">
                  Showcase your personality to attract brands with aligned
                  values.
                </p>
              </div>
            </div>
          }
          key="5"
        >
          <div className="bg-transparent sm:bg-[#fff] rounded-lg p-0 sm:p-6 pb-10 flex flex-col ">
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/Interests.svg"
                  alt="Interests"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Interests Outside of Sports
                </h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">{(athleteNilData?.hobbies)?(athleteNilData?.hobbies).join(', '):'-'}</h6>
            <Divider className="my-7" />
            <div className="flex gap-[12px] items-center mb-6">
              <div className="min-w-11 h-11 rounded bg-[#D8E2FF] flex items-center justify-center">
                <Image
                  src="/images/Teams.svg"
                  alt="Teams"
                  shadow="none"
                  radius="none"
                  width={"24px"}
                  height={"24px"}
                  preview={false}
                />
              </div>
              <div>
                <h6 className="text-[16px] font-semibold">
                  Involved In Community
                </h6>
              </div>
            </div>
            <h6 className="text-sm font-normal">
            {(athleteNilData?.community_involvement)?(athleteNilData?.community_involvement).join(', '):'-'}
            </h6>
          </div>
        </Panel>
      </Collapse>
    </>
  );
};
export default Nil;
