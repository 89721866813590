import { useState } from "react";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
const SortingArrow = ({ onSort, field }) => {
  const [isSortedAsc, setIsSortedAsc] = useState(true);
  const handleSort = () => {
    const newSortDirection = !isSortedAsc;
    setIsSortedAsc(newSortDirection);
    onSort(field, newSortDirection ? "desc" : "asc"); // Call the onSort prop with the current sort direction
  };
  return (
    <div onClick={handleSort} className="cursor-pointer inline ml-1">
      {isSortedAsc ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
    </div>
  );
};
export default SortingArrow;