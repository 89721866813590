import React, { useRef, useState, useEffect } from "react";
import { Modal, Button } from "antd";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import { getFileNameWithRandomNumber } from '../../utils/validation';
import { addObject } from "../../utils/awsS3";
import { Config } from "../../constants/Config";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";
import imageCompression from 'browser-image-compression';

const ImageCropper = ({ isOpen, onClose, onCrop }) => {
  // const { t } = useTranslation();
  // const { decodedToken, saveToken } = useToken();
  const { showAlert } = useAlert();
  const [image, setImage] = useState(null);
  const { setLoading } = useLoader();
  const [croppedImage, setCroppedImage] = useState(null);
  // const { showSpinner, hideSpinner } = useLoading(); // Uncomment if useLoading is needed
  const cropperRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      setImage(null);
      setCroppedImage(null);
    }
  }, [isOpen]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const getCropData = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("file-input");
    fileInput.click();
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const uploadFileToS3Bucket = async (file) => {
    // showSpinner();
    if (!file) {
      // showAlert("Please select a file first!", "error");
      // hideSpinner();
      return;
    }
    setLoading(true);
    try {
      const newFileName = getFileNameWithRandomNumber(file.name);
      const response = await addObject(newFileName, file);
      const publicUrl = `https://${Config?.s3BucketName}.s3.${Config?.s3Region}.amazonaws.com/${newFileName}`;
      // await ProfileSer.userUpdateImage(publicUrl);
      onCrop(publicUrl);
      setImage(null);
    } catch (error) {
      // showAlert(error.message, "error");
      // hideSpinner();
    } finally {
      // hideSpinner();
    }
    setLoading(false);
  };

  const handleUpload = async () => {
    getCropData();
    if (croppedImage) {
      const file = dataURLtoFile(croppedImage, 'college_image.png');
      
      try {
        const compressedFile = await compressImage(file);
        await uploadFileToS3Bucket(compressedFile);
        onClose();
      } catch (error) {
        showAlert(error.message, 'error');
      }
    } else {
      showAlert('Please crop the image first!', 'error');
    }
  };
  // const handleUpload = () => {
  //   getCropData();
  //   if (croppedImage) {
  //     const file = dataURLtoFile(croppedImage, 'profile_image.png');
  //     uploadFileToS3Bucket(file);
  //     onClose();
  //   } else {
  //     // showAlert("Please crop the image first!", "error");
  //   }
  // };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.5, // Target maximum size in MB
      maxWidthOrHeight: 1024, // Maximum width or height to maintain aspect ratio
      useWebWorker: true, // Use web workers for better performance
    };
  
    try {
      const compressedFile = await imageCompression(file, options);
      console.log(`Original file size: ${(file.size / 1024 / 1024).toFixed(2)} MB`);
      console.log(`Compressed file size: ${(compressedFile.size / 1024 / 1024).toFixed(2)} MB`);
      return compressedFile;
    } catch (error) {
      throw new Error('Image compression failed: ' + error.message);
    }
  };
  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      centered
    >
      <div className="modal-content">
        <div className="modal-header mb-3">
          <h6 className="font-medium">Upload Profile Photo</h6>
          <p>For best results, use a PNG, JPG, or JPEG image of max 5 MB size.</p>
        </div>
        <div className="modal-body">
          <div className="file-input-wrapper">
            <input
              id="file-input"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            {!image && (
              <div  className="border-dashed border border-[#d9d9d9] w-[100px] h-[100px] rounded-lg flex flex-col justify-center items-center self-center" onClick={handleClick}>
                <img src={`${Config.appUrl}images/data-upload.svg`} alt="Upload" className=" h-[24px] w-[24px] object-cover rounded overflow-hidden cursor-pointer"/>
                <h6>Upload</h6>
              </div>
            )}
            {image && (
              <Cropper
                src={image}
                style={{ height: 300, width: "100%" }}
                initialAspectRatio={1}
                guides={false}
                ref={cropperRef}
                crop={getCropData}
                wheelZoomRatio={0}
              />
            )}
          </div>
        </div>
        <div className="modal-footer pt-5">
          <Button onClick={handleUpload} disabled={!image} type="primary">
            Upload
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImageCropper;
