import { Pagination } from "antd";
const PaginationComponent = ({ currentPage, total, pageSize, onPageChange }) => {
  const handlePageChange = (page, size) => {
    onPageChange(page, size);
  };
  return (
    <div className="mt-3">
   <Pagination
      className="cursor-pointer"
      showSizeChanger
      onChange={handlePageChange}
      onShowSizeChange={handlePageChange}
      current={currentPage}
      total={total}
      pageSize={pageSize}
    />
    </div>
  );
};
export default PaginationComponent;