import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { MailOutlined } from "@ant-design/icons";

const EditEmailAthlete = ({ isOpen, onOk, onCancel }) => {
  const [checkInboxModalVisible, setCheckInboxModalVisible] = useState(false);

  const handleConfirmClick = () => {
    onCancel();
    setCheckInboxModalVisible(true); 
  };

  const handleCheckInboxModalCancel = () => {
    setCheckInboxModalVisible(false); 
  };
  return (
    <>
      <Modal
        title="Edit Email"
        centered
        open={isOpen}
        onCancel={onCancel}
        onOk={handleConfirmClick}
        okText="Confirm"
      >
        <Form
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          style={{ marginTop: 24 }}
          autoComplete="off"
        >
          <Form.Item
            label="Old Email"
            name="Old Email"
            rules={[
              {
                required: true,
                message: "Please enter your Old Email!",
              },
            ]}
          >
            <Input
              size="large"
              defaultValue="john.smith15@gmail.com"
              disabled
            />
          </Form.Item>
          <Form.Item
            label="New Email"
            name="New Email"
            rules={[
              {
                required: true,
                message: "Please enter your New Email!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal centered open={checkInboxModalVisible} footer={null} closable={false} width={370}>
        <div className="text-center">
          <MailOutlined className="text-4xl mb-2" />
          <h2 className="text-lg font-bold">Check Inbox!</h2>
          <p className="mb-2">
            A verification link is sent on your email address. Please check and
            accept the terms and conditions to change your email on the
            platform.
          </p>
          <Button type="primary" onClick={handleCheckInboxModalCancel}>
            Check Email
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default EditEmailAthlete;
