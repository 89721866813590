import React, { useEffect, useState } from "react";
import { Typography, Button, Input, Form, Layout, Card } from "antd";
import { useLocation } from "react-router-dom";
import SuccessfullyModal from "../../../Modal/SuccessfullyModal";
import WarningModal from "../../../Modal/WarningModal";
import AuthService from "../../../service/AuthService";
import { useAlert } from "../../../contexts/AlertProvider";

const { Title } = Typography;
const AuthSer = new AuthService();

const validatePassword = (_, value) => {
  if (!value) {
    return Promise.reject(new Error("Please enter your new password."));
  }

  if (value.length < 10 || value.length > 50) {
    return Promise.reject(new Error("Password must be between 10 and 50 characters."));
  }

  const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=]{10,50}$/;
  if (!pattern.test(value)) {
    return Promise.reject(new Error("Password must contain at least one letter and one number."));
  }

  return Promise.resolve();
};

const ResetPassword = () => {
  const { showAlert } = useAlert();
  const location = useLocation();
  const [isSuccessfullyModalOpen, setIsSuccessfullyModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [form] = Form.useForm();
  const [code, setCode] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get("code");
    if (codeParam) {
      setCode(codeParam);
    }
  }, [location]);

  const showSuccessfullyModal = () => {
    setIsSuccessfullyModalOpen(true);
  };
  const handleSuccessfullyOk = () => {
    setIsSuccessfullyModalOpen(false);
  };
  const handleSuccessfullyCancel = () => {
    setIsSuccessfullyModalOpen(false);
  };
  const showWarningModal = () => {
    setIsWarningModalOpen(true);
  };
  const handleWarningModalOk = () => {
    setIsWarningModalOpen(false);
  };
  const handleWarningModalCancel = () => {
    setIsWarningModalOpen(false);
  };

  const handleSubmit = async (values) => {
    const { password, confirmPassword } = values;

    setIsDisabled(true);
    try {
      const response = await AuthSer.resetPassword(code, password, confirmPassword);
      if (response.message) {
        showAlert(response.message, "success");
        showSuccessfullyModal();
        form.resetFields();
      }
    } catch (error) {
      let msg = AuthSer.errorMessage(error);
      showAlert(msg, "error");
      showWarningModal();
    }
    setIsDisabled(false);
  };

  return (
    <>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <Card className="relative">
            <img
              width={450}
              src="logo.png"
              alt=""
              className="mx-auto absolute top-[-90px] left-0 right-0"
            />
            <Title level={3} align="center">
              Reset Password
            </Title>
            <Form
              form={form}
              layout="vertical"
              style={{
                maxWidth: 400,
                minWidth: 400,
                marginTop: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Passwords do not match."));
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
              <Button
                className="w-full mt-4"
                size="large"
                type="primary"
                htmlType="submit"
                disabled={isDisabled}
              >
                Change Password
              </Button>
            </Form>
          </Card>
        </div>
      </Layout>
      <SuccessfullyModal
        isOpen={isSuccessfullyModalOpen}
        onOk={handleSuccessfullyOk}
        onCancel={handleSuccessfullyCancel}
      />
      <WarningModal
        isOpen={isWarningModalOpen}
        onOk={handleWarningModalOk}
        onCancel={handleWarningModalCancel}
      />
    </>
  );
};

export default ResetPassword;
