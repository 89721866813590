import { Card, Divider, Tabs, Tooltip, Typography } from "antd";
import { LeftOutlined,EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams} from "react-router-dom";
import PersonalInformation from "./ViewAthleteDetails/PersonalInformation";
import SocialMedia from "./ViewAthleteDetails/SocialMedia";
import AcademicDetails from "./ViewAthleteDetails/AcademicDetails";
import SportsDetails from "./ViewAthleteDetails/SportsDetails";
import SubscriptionDetails from "./ViewAthleteDetails/SubscriptionDetails";
import AppliedApplications from "./ViewAthleteDetails/AppliedApplications";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { useState } from "react";
import AthleteService from "../../service/AthleteService";
import { useAlert } from "../../contexts/AlertProvider";
import { decodeId } from '../../utils/encoding';
import Nil from "./ViewAthleteDetails/nil";
const AthleteSer = new AthleteService();
const { TabPane } = Tabs;
const ViewAthlete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { showAlert } = useAlert();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleDeleteOk =async () => {
    try {
    let athleteId = decodeId(id);
    const response = await AthleteSer.updateAthleteStatus(athleteId, 'deleted');
    setIsDeleteModalOpen(false);
    navigate('/manage-athlete');
    showAlert(response?.message, "success");
    } catch (error) {
      let msg = AthleteSer.errorMessage(error);
      showAlert(msg, "error");
    }
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <div>
            <Typography
              className="text-xl font-semibold"
              align={"left"}
            
            >
              <LeftOutlined
                className="text=lg text-black mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              View Athlete Details
            </Typography>
          </div>
          <div>
                <Tooltip title="Edit">
                  <EditOutlined
                    onClick={() => navigate(`/edit-athlete/${id}`)}
                    className="mx-1.5 cursor-pointer"
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteOutlined
                    className="mx-1.5 cursor-pointer"
                    onClick={() => setIsDeleteModalOpen(true)}
                  />
                </Tooltip>
              </div>

        </div>
        <Divider />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Personal Information" key="1">
            <PersonalInformation />
          </TabPane>
         
          <TabPane tab="Academic Details" key="2">
            <AcademicDetails />
          </TabPane>
          <TabPane tab="Sports Details" key="3">
            <SportsDetails />
          </TabPane>
          <TabPane tab="Uploaded Files " key="4">
            <SocialMedia />
          </TabPane>
          <TabPane tab="Subscription Details" key="5">
            <SubscriptionDetails />
          </TabPane>
          <TabPane tab="Applied Applications" key="6">
            <AppliedApplications />
          </TabPane>
          <TabPane tab="Nil" key="7">
            <Nil />
          </TabPane>
        </Tabs>
      </Card>
       {/* delete modal */}
       <ConfirmationModal
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this Athlete?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};
export default ViewAthlete;