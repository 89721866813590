import { useState, useEffect, useRef } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Divider, Form, Input, Row, Typography } from "antd";
import PlanService from "../../service/PlanService";
import { useLoader } from "../../contexts/LoadingProvider";
import { useAlert } from "../../contexts/AlertProvider";
const PlanSer = new PlanService();
const EditSubscriptionDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      //setIsEditing(true);
      const fetchProfileData = async () => {
        const response = await PlanSer.getPlanDetails(id);
        const data = response?.data;
        if (data) {
          form.setFieldsValue(data);
        }
      };
      fetchProfileData();
    } else {
      //setIsDisabled(false);
    }
  }, [id, form]);
  const handleSubmit = async (values) => {
    try {
      const formattedData = {
        ...values,
      };

      formattedData.id = parseInt(id);
      let response = await PlanSer.updatePlanDetails(formattedData);
      showAlert(response?.message, "success");
    
      navigate(-1);
    } catch (error) {
      let msg = PlanSer.errorMessage(error);
      showAlert(msg, "error");
      // setIsDisabled(false);
    }
  };
  
  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <div>
            <Typography className="text-xl font-semibold" align={"left"}>
              <LeftOutlined
                className="text=lg text-black mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              Edit Subscription Plan Details
            </Typography>
          </div>
        </div>
        <Divider />
        <div>
        <Form
          form={form}
          layout="vertical"
          style={{
            //   maxWidth: 600,
            width: "100%",
          }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleSubmit}
        >
            <Row>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Plan Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Plan Name.",
                      },
                    ]}
                  >
                    <Input size="large" maxLength={200}/>
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Upload Image limit"
                    name="image_restrict"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Upload Image limit.",
                      },
                    ]}
                  >
                    <Input size="large" minLength={1}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Search College limit"
                    name="search_restrict"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Search College limit.",
                      },
                    ]}
                  >
                    <Input size="large" minLength={1}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Sport Choose Limit"
                    name="sports_restrict"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Benefits.",
                      },
                    ]}
                  >
                    <Input size="large" minLength={1}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Amount.",
                      },
                    ]}
                  >
                    <Input size="large" readOnly disabled/>
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Upload video limit"
                    name="video_restrict"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Upload video limit.",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Coach Contact Limit"
                    name="send_application_restrict"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Contact restriction.",
                      },
                    ]}
                  >
                    <Input size="large" minLength={1}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Video overlay report per month limit"
                    name="video_overlay_restrict"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Video overlay report per month limit.",
                      },
                    ]}
                  >
                     <Input size="large" minLength={1}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Divider />
            <div className="text-right">
                <Button type="primary" htmlType="submit">
                Update
                </Button>
            </div>
          </Form>
          
        </div>
      </Card>
    </>
  );
};
export default EditSubscriptionDetails;
