import React from 'react';
import Routing from "./Routes/routing";
import { TokenProvider } from "./contexts/TokenProvider";
import AlertProvider from "./contexts/AlertProvider";
import { LoaderProvider } from "./contexts/LoadingProvider";
const App = () => {
  return (
    <React.Fragment>
      <LoaderProvider>
        <AlertProvider>
          <TokenProvider>
            <Routing />
          </TokenProvider>
        </AlertProvider>
      </LoaderProvider>
    </React.Fragment>
  );
};
export default App;