import { Tooltip } from "antd";
import { useState, useEffect, useCallback } from "react";
import PaginationComponent from "../../../Common/Pagination";
import SortingArrow from "../../../Common/SortingArrow";
import { DownloadOutlined } from "@ant-design/icons";
import MotionService from "../../../service/MotionService";
import { useAlert } from "../../../contexts/AlertProvider";
import { useLoader } from "../../../contexts/LoadingProvider";
import moment from 'moment';
const MotionSer = new MotionService();
const SubscriptionPlan = ({ filters, setFilters, activeTab }) => {
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [subscriptionListing, setSubscriptionListing] = useState([]);
  
  const fetchData = useCallback(async () => {
    try {
      //setLoading(true);
      const response = await MotionSer.getSubscriptionList(filters);
      setSubscriptionListing(response?.data);
      //setLoading(false);
    } catch (error) {
      let msg = MotionSer.errorMessage(error);
      showAlert(msg, "error");
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (activeTab === "1") {
      fetchData();
    }
  }, [activeTab, fetchData]);

  // useEffect(() => {
  //   const filterSubscription = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await MotionSer.getSubscriptionList(filters);
  //       setSubscriptionListing(response?.data);
  //       setLoading(false);
  //     } catch (error) {
  //       let msg = MotionSer.errorMessage(error);
  //       showAlert(msg, "error");
  //     }
  //   };

  //   filterSubscription();
  // }, [filters, setLoading, showAlert]);

  const handlePageChange = (page, size) => {
    setFilters(prevFilters => ({ ...prevFilters, page:page, limit: size }));
  };

  const handleSort = (field, key) => {
    setFilters(prevFilters => ({ ...prevFilters, page:1, sort:field, order: key }));
  };
  
  const downLoadInvoice = async (pdf) =>{
    setLoading(true);
    window.open(pdf, '_blank');
    setLoading(false);
  }

  const exportData = async () =>{
    
    setLoading(true);
    try {
      const response = await MotionSer.getExportSubscription();
      if(response?.link){
        window.open(response?.link, '_blank');
      }
    }catch (error) {
      let msg = MotionSer.errorMessage(error);
      showAlert(msg, "error");
    }
    setLoading(false);
  }
  return (
    <>
      <div className="flex justify-end mb-4">
        <Tooltip title="Export">
          <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" onClick={exportData} />
        </Tooltip>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-left text-sm  text-gray-500">
          <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Athlete name <SortingArrow onSort={handleSort} field="first_name"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Athlete email address <SortingArrow onSort={handleSort} field="email"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Plan name <SortingArrow onSort={handleSort} field="plan_name"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Plan price <SortingArrow onSort={handleSort} field="amount"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Transaction ID <SortingArrow onSort={handleSort}  field="transaction_id"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Transaction Date & Time <SortingArrow onSort={handleSort} field="created_at"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
              Transaction Status <SortingArrow onSort={handleSort} field="pay_status"/>
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Payment card number <SortingArrow onSort={handleSort} field="payment_method" />
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          {subscriptionListing?.rows?.length > 0 ? (
              subscriptionListing.rows.map((sub, index) => (
            <tr key={index} className="bg-white border-b">
              <td className="px-3 py-4 vertical-top">{sub?.user?.first_name} {sub?.user?.last_name}</td>
              <td className="px-3 py-4 vertical-top">{sub?.user?.email}</td>
              <td className="px-3 py-4 vertical-top">{sub?.subscription_plan?.name}</td>
              <td className="px-3 py-4 vertical-top">{sub?.subscription_plan?.amount}</td>
              <td className="px-3 py-4 vertical-top">{(sub?.transaction_id)?sub?.transaction_id:'-'}</td>
              <td className="px-3 py-4 vertical-top">{moment(sub?.created_at).format('MM/DD/YYYY hh:mm A')}</td>
              <td className="px-3 py-4 vertical-top">
              <p className={`text-xs font-medium bg-[#01940033] rounded-lg text-center p-2 capitalize ${
                      sub?.pay_status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                      (sub?.pay_status === 'completed') ? 'text-[#019400] bg-[#01940033]' : 'text-[#DC362E] bg-[#DC362E40]'
                    }`}>{sub?.pay_status}</p></td>
              <td className="px-3 py-4 vertical-top">{sub?.payment_method ? `************${sub?.payment_method}` : '-'}</td>
              <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                {(sub?.pdf) ?(
                <Tooltip title="Download invoice">
                  <DownloadOutlined className="mx-1.5 cursor-pointer text-[20px]" onClick={()=>downLoadInvoice(sub?.pdf)}/>
                </Tooltip>
                ):'-'}
              </td>
            </tr>
            ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center px-3 py-4 text-gray-500">
                  No records found
                </td>
              </tr>
            )}
            {/* Manage developer side */}
            {/* <tr>
            <td colSpan={9} className="text-center">
              <EmptyComponent />
            </td>
          </tr> */}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center pt-5">
          <div className="font-semibold text-base">
            Total count : <span>{subscriptionListing?.count}</span>
          </div>
          {subscriptionListing?.count > 10 && (
          <PaginationComponent
            currentPage={filters.page}
            total={subscriptionListing?.count}
            pageSize={filters.limit}
            onPageChange={handlePageChange} />
          )}
        </div>
    </>
  );
};
export default SubscriptionPlan;
